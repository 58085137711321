//working also on IE11
export const getParamFromUrl = (name) => {
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if ( results === null ) {
    return null;
  } else {
    return decodeURI(results[1]) || 0;
  }
};

export const onbWebStatusTransformer = (status) => {
  switch(status){
    case 'init':
      return 0;
    case 'user_info':
      return 1;
    case 'contract_info':
      return 2;
    case 'supplies':
      return 3;
    case 'payment':
      return 4;
    case 'summary':
      return 5;
    case 'complete':
      return 6;
    default:
    return 0;
  }
};

export const actWebStatusTransformer = (status) => {
  switch(status){
    case 'step1':
      return 0;
    case 'step2':
      return 0;
    case 'step3':
      return 1;
    case 'step4':
      return 2;
    default:
    return 0;
  }
}

export const getDetailId = () => {
  const pathname = window.location.pathname;
  // const { pathname } = state.router.location;
  if (pathname) {
    return pathname.split('/').pop();
  }
  return null;
};

export  const statusMap = {
  'new': 'nuovo',
  'valid': 'valido',
  'invalid': 'utilizzato',
  'expired': 'non più valido',
  'used': 'utilizzato'
};

export const voucherTypesMap = {
  'travel': 'Buono viaggio',
  'digital': 'Buono digitale',
  'COOP': 'Buono digitale',
  'ALG': 'Buono digitale',

};

export const voucherIconMap = {
  'travel': 'plane',
  'digital': 'shopping',
  'COOP': 'shopping',
  'ALG': 'travel'
};

export  const warningLabelMap = {
  'new': 'Valido fino al:',
  'valid': 'Mancano 2 giorni',
  'expired': 'Non più valido dal:',
  'invalid': '',
  'used': 'Utilizzato il'
};