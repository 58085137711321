import React, { useState }from 'react';
import classnames from 'classnames';

import { Button } from '../../ui/atoms/button';
import { Icon } from '../../ui/atoms/icon';

import style from './general_status.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const GeneralStatus = ({
  onClickCallback,
  status,
  message,
  className
}) => {
  const [status_message, set_status_message] = useState(message);

  return (
    <div className={classnames(style.invalidation, className)}>

      {status === 'accept' &&
        <div>
          <Icon className={style.icon} name='statusAccept'/>
          <p className={style.message}> {status_message}</p>
        </div>
      }

      {status === 'warning' &&
        <div>
          <Icon className={style.icon} name='statusWarning'/>
          <p className={style.message}> {status_message}</p>
        </div>
      }

      {status === 'error' &&
        <div>
          <Icon className={style.icon} name='statusError'/>
          <p className={style.message}> {status_message}</p>
        </div>
      }


      <div className={style.buttonContainer}>
          <Button
            className={style.cancel}
            onClick={onClickCallback}
          >ok</Button>
      </div>

    </div>
  );
};


GeneralStatus.propTypes = propTypes;
GeneralStatus.defaultProps = defaultProps;

export { GeneralStatus };
