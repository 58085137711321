import React from 'react';
import classnames from 'classnames';

import style from './header.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
  variant: 'big'
};

const Header = ({
  variant,
  title,
  title2,
  subtitle,
  subtitle2,
  subtitle3,
  email,
  subtitleBold,
  className,
  ...otherProps
}) => (
  <div className={classnames(style.header, style[variant], className)} {...otherProps}>
    <h1 className={style.title}>{title}</h1>
    {title2 && (
      <h1 className={style.title}>{title2}</h1>
    )}
    <hr className={style.divisor}/>
    <p className={classnames(style.subtitle, style.subtitleBold)}>{subtitleBold}</p>
    <p className={style.subtitle}>{subtitle}</p>
    {subtitle2 && (
      <p className={style.subtitle}>{subtitle2}</p>
    )}
    {subtitle3 && (
      <div>
        <span className={style.subtitle}>{subtitle3}</span>
        <span className={style.email}> {email}</span>
      </div>
    )}
  </div>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export { Header };
