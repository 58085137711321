import React, {PureComponent} from 'react';

class WithModal extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      modalState: {}
    };
  }

  registerModal = (key) => {
    this.setState(({modalState}) => ({
      modalState: {
        ...modalState,
        [key]: false
      }
    }));
  }

  toggleModal = (key) => {
    this.setState(({modalState}) => ({
      modalState: {
        ...modalState,
        [key]: !modalState[key]
      }
    }));

    if(!this.state.modalState[key]) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }

  render() {
    const { Component, ...otherProps } = this.props;
    const {modalState} = this.state;
    return (
      <Component
      registerModal={this.registerModal}
      toggleModal={this.toggleModal}
      modalState={modalState}
      {...otherProps} />
    );
  }
}

const withModal = (component) => (
  (props) => (<WithModal Component={component} {...props} />)
);

export { withModal };