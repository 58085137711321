import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../../core/config/routes';

export const ProtectedRoute = ({ guard , Component }) => {
  return (
    <Route render={
      (props) => {
        return guard ? <Component {...props}/>
        : <Redirect to={{pathname: routes.login.url, state: {from: props.location.pathname}}}/>;
      }
    } />
  );
};
