import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import WhiteLogo from '../../commons/assets/white-logo.png';
import BgDesktop from '../../commons/assets/bg-desktop.png';
import { routes } from '../../core/config/routes';
import style from './general.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const GeneralView = ({
  className,
  children,
  logout,
  logoCenter,
  ...otherProps
}) => {


  return (
    <div className={style.general}>

      <img className={classnames(style.bg)} src={BgDesktop} alt='logo coop' />
      <img className={classnames(style.bg)} src={BgDesktop} alt='logo coop' />

      <div className={classnames(style.container)}>

        {/* desktop height 116px */}
        <div className={classnames(style.centered)}>
        <Link to={ routes.truffe.path }> 
          <img className={classnames(style.logo)} src={WhiteLogo} alt='logo coop'/>
        </Link>
        </div>
        
  
  
        <div className={classnames(style.content, className)}>
          {children}
        </div>
  
        {/* desktop height 90px */}
        <div className={style.footer}>
          <p className={style.title}>Alleanza luce & gas S.p.A.</p>
          <p className={style.subtitle}>Via Villanova 29/7, 40055 – Villanova di Castenaso – BO Italy – Iscrizione Reg. Imp. BO – 515162 – C.F. e P.I.03386091205</p>

          <div className={style.links}>
            <Link to={ routes.privacy.path }>Informativa sulla privacy</Link> - <Link to={ routes.policy.path }>Cookie Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

GeneralView.propTypes = propTypes;
GeneralView.defaultProps = defaultProps;

export const General = GeneralView;
