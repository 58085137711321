import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import ReactTooltip from 'react-tooltip'

import { Icon } from '../icon';


import style from './input.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const Input = ({
  warning,
  type,
  status,
  placeholder,
  name,
  label,
  plusComponent,
  labelComponent,
  handleFocus,
  handleBlur,
  changeType,
  visible,
  showerror,
  errormsg,
  className,
  showTooltip,
  tooltip,
  disabledStyle,
  ...otherProps
}) => (
  <div className={classnames(style.input, {[style.inline]: type === 'checkbox' }, className)} {...otherProps}>
    <div className={style.labels}>
      {label &&
        <div className={style.label}>
          {label}
        </div>
      }
      {plusComponent}
    </div>
    <div className={style.fieldContainer}>
      <Field
        onFocus={handleFocus}
        onBlur={handleBlur}
        type={type}
        name={name}
        className={classnames(
          disabledStyle == true?
          style.disabledStyle:
          null,
          style.field,
          style[status],
          )}
        placeholder={placeholder}
        component={type === 'textarea' ? 'textarea' : undefined}
      />
      {labelComponent}
      <div className={style.eye_form, (status === 'invalid' || status === 'valid') ? style.lefteye : null}>
          {type === 'password'  && visible === 'show_eye' && <Icon className={style.icon} name='eye'      onClick={changeType} />}
          {type === 'text'      && visible === 'show_eye' && <Icon className={style.icon} name='HidePwd'  onClick={changeType} />}
          {type === 'text'      && status  === 'info'     && <a data-tip data-for='info'><Icon className={classnames(style.infoIcon, style.icon)} name='info' /></a>}
      </div>
      {status=='info' && (
        <ReactTooltip className={style.tooltip} place="top" id="info" type="ligth" effect="solid">
          Il POD (Point of Delivery) indica il punto fisico 
          di consegna di energia elettrica e il PDR 
          (Punto di riconsegna) specifica il punto fisico di 
           consegna del gas. Entrambi sono codici univoci
          nazionali di 14 caratteri che trovi facilmente 
          sulla tua bolletta.
        </ReactTooltip> 
      )}

      <div className={style.valid_icons}>
          {type !== 'checkbox' && status==='valid'    && <Icon className={style.icon} name='valid' />}
          {type !== 'checkbox' && status==='invalid'  && <Icon className={style.icon} name='invalid' />} 
          {type !== 'checkbox' && status==='focused'  && <Icon className={style.icon} name='focuse' />} 

      </div>
    </div>
      {
        (type !== 'checkbox'  ) ?
          <div className={classnames(style.errorField)}>
            <span className={classnames(style.formText, style.textMuted)}>{errormsg}</span>
          </div>
          :
          null
      }
    {warning && <span className={style.warning}>{warning} !  </span>}
  </div>
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input };
