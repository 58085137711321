import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './plus-minus.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const PlusMinus = ({
  status,
  className,
  ...otherProps
}) => (
  <div className={classnames(style.plusMinusCircle, {[style.open]: status, [style.close]: !status}, className)}>
    <div className={style.plusMinus}/>
  </div>
);

PlusMinus.propTypes = propTypes;
PlusMinus.defaultProps = defaultProps;

export { PlusMinus };
