import React from 'react';
import classnames from 'classnames';

import { General } from '../../components/template/general';
import { Accordion } from '../../components/ui/organisms/accordion';
import { Breadcrumb } from '../../components/ui/molecules/breadcrumb';

import { routes } from '../../core/config/routes';


import style from './policy.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
  breadcrumbTitle:'Torna indietro',
  breadcrumbUrl: routes.truffe.path
};

const Policy = ({ className, breadcrumbUrl, breadcrumbTitle }) => (
  <General>
    <div className={classnames(style.policy, className)}>
      <Accordion title="policy" iconName="privacy" blocked>
        <div className={style.policyContent}>
          <Breadcrumb
            className={style.breadcrumb}
            label={breadcrumbTitle}
            url={breadcrumbUrl}
          />
          <h2 className={style.title}>Cookie Policy</h2>
          <h4>
            TITOLARE DEL TRATTAMENTO E RESPONSABILE DELLA PROTEZIONE DEI DATI
          </h4>
          <br />
          <br />
          <span>
            Il titolare del trattamento è Alleanza Luce & Gas S.p.A., Società
            soggetta alla Direzione e Coordinamento di Coop Alleanza 3.0 Società
            Cooperativa con Sede Legale in Via Villanova n. 29/7, 40055
            Villanova di Castenaso (BO) ed Uffici in Via Trattati Comunitari
            Europei 1957-2007 n. 13, 40127 Bologna (BO), C.F. e P. IVA
            03386091205, R.E.A. di Bologna n. 515162, capitale sociale 5.000.000
            I.V., e-mail:{" "}
          </span>
          <a href="mailto:servizioclienti@accendilucegas.it">
            servizioclienti@accendilucegas.it
          </a>
          <span>, fax 051/6376199, numero verde 800 208468.</span>
          <span>
            L’utente potrà inviare qualsiasi istanza relativa al trattamento dei
            suoi dati personali, indirizzandola al Responsabile della Protezione
            dei Dati all’indirizzo e-mail:{" "}
          </span>
          <a href="mailto:DataProtectionOfficer@alleanzalucegas.it">
            DataProtectionOfficer@alleanzalucegas.it
          </a>
          <br />
          <br />
          <h5>PREMESSA</h5>
          <br />
          <p>
            L’utilizzo dei cookie rientra nella politica di tutela della privacy
            di ALG, proprietaria di questo sito e Titolare del trattamento di
            dati personali, in conformità a quanto previsto dal Reg. UE 2016/679
            (GDPR – General data Protection Regulation – è il Regolamento
            Europeo sulla protezione delle persone fisiche con riguardo ai loro
            dati personali), dal D. Lgs. 196/03 “Codice in materia di protezione
            dei dati personali” (Codice Privacy), dal Provvedimento dell’8
            maggio 2014 “Individuazione delle modalità semplificate per
            l’informativa e l’acquisizione del consenso per l’uso dei cookie”,
            disposizione emanata dall’Autorità Garante della protezione dei
            dati.
          </p>
          <br />
          <h5>CHE COSA SONO I COOKIE</h5>
          <br />
          <p>
            Un cookie è un piccolo file contenente una stringa di caratteri che
            viene inviata al computer e/o apparato mobile dell’utente quando
            visita un sito web. Alla visita successiva dello stesso sito, il
            cookie consente al sito di riconoscere il browser. I cookie
            permettono di migliorare il funzionamento e le prestazioni del sito
            e raccolgono informazioni anche per fini statistici o pubblicitari,
            principalmente per personalizzare l’esperienza di navigazione,
            ricordando le preferenze o la lingua ed altre impostazioni. L’utente
            può reimpostare il browser in modo da rifiutare tutti i cookie o da
            indicare quando viene inviato un cookie. In questi casi, è possibile
            che alcuni servizi o caratteristiche tecniche non funzionino
            correttamente senza cookie.
          </p>
          <br />
          <h5>TIPI DI COOKIE UTILIZZATI</h5>
          <br />
          <span>Il sito </span>
          <a href="www.accendilucegas.it"> www.accendilucegas.it </a>
          <span>
            {" "}
            utilizza diverse tipologie di cookie ognuna delle quali ha una
            specifica funzione.
          </span>
          <p>Di seguito, una lista riassuntiva dei cookie utilizzati.</p>
          <ul>
            <li>
              <strong>Cookie di Navigazione: </strong>
              <span>
                sono cookie necessari al funzionamento del sito e permettono una
                navigazione e fruizione ottimale dei contenuti presenti
              </span>
            </li>
            <li>
              <strong>Cookie Funzionali: </strong>
              <span>
                sono cookie che permettono di riconoscere gli utenti durante gli
                accessi successivi, in modo da ricordare le preferenze
                selezionate durante le visite precedenti
              </span>
            </li>
            <li>
              <strong>Cookie Analitici: </strong>
              <span>
                sono cookie impiegati per raccogliere dati in forma anonima e
                aggregata ai fini di analisi statistica degli accessi e delle
                modalità di fruizione del sito. A ogni computer e dispositivo
                collegato a Internet è assegnato un numero esclusivo come
                indirizzo del protocollo Internet (IP). Poiché questi numeri
                sono in genere assegnati a blocchi in base al Paese, un
                indirizzo IP può essere utilizzato spesso per identificare il
                Paese, la provincia e la città da cui un computer si collega a
                Internet. Inoltre, i cookie analitici che utilizzano il metodo
                noto come masking IP, consentono la visualizzazione solo di una
                parte dell’indirizzo IP per la geolocalizzazione, anziché
                l’indirizzo intero dell’Utente
              </span>
            </li>
            <li>
              <strong>Cookie di Terze Parti: </strong>
              <span>
                visitando un sito internet, l’utente può ricevere cookie sia dal
                sito visitato (prima parte), sia da siti cosiddetti di terze
                parti (un esempio è quello dei “social plugin” che consentono la
                condivisione di contenuti online). L’utilizzo di plugin di terza
                parte comporta la trasmissione di cookie da e verso tutti i siti
                gestiti esternamente. La gestione di informazioni di questo tipo
                è disciplinata dalle informativa ospitata dal sito di ciascuna
                terza parte a cui l’utente dovrà fare riferimento
              </span>
            </li>
            <li>
              <strong>Cookie sulla posizione: </strong>
              <span>
                – Geolocalizzazione – cookie di terze parti (es.Google): quando
                un utente utilizza servizi Google, lo stesso può raccogliere ed
                elaborare informazioni sulla sua sua posizione, utilizzando
                varie tecnologie per stabilire la posizione, inclusi indirizzo
                IP, GPS e altri sensori che potrebbero, fornire informazioni sui
                dispositivi, sui punti di accesso Wi-Fi e sui ripetitori dei
                cellularei nelle vicinanze.
              </span>
            </li>
          </ul>
          <p>
            Un altro modo per distinguere i cookie utilizzati da questo sito, è
            la suddivisione in:
          </p>
          <ul>
            <li>
              <strong>Cookie necessari </strong>
              <span>
                {" "}
                (i cookie necessari aiutano a contribuire a rendere fruibile un
                sito web abilitando le funzioni di base come la navigazione
                della pagina e l’accesso alle aree protette del sito. Il sito
                web non può funzionare correttamente senza questi cookie);
              </span>
            </li>
            <li>
              <strong>Cookie statistici </strong>
              <span>
                {" "}
                (i cookie statistici aiutano i proprietari del sito web a capire
                come i visitatori interagiscono con i siti raccogliendo e
                trasmettendo informazioni in forma anonima);
              </span>
            </li>
            <li>
              <strong>Cookie di marketing </strong>
              <span>
                {" "}
                (i cookie per il marketing vengono utilizzati per monitorare i
                visitatori nei siti web. L’intento è quello di visualizzare
                annunci pertinenti e coinvolgenti per il singolo utente e quindi
                quelli di maggior valore per gli editori e gli inserzionisti
                terzi;
              </span>
            </li>
            <li>
              <strong>Cookie non classificati </strong>
              <span>
                {" "}
                (i cookie non classificati sono i cookie che sono in fase di
                classificazione, insieme ai fornitori di cookie individuali).
              </span>
            </li>
          </ul>
          <br />
          <h5>
            COME SI POSSONO DISABILITARE I COOKIE E GESTIRE LE PREFERENZE?
          </h5>
          <br />
          <span>
            È possibile gestire le preferenze sull’utilizzo di cookie attraverso
            il browser utilizzato. Accedendo alle impostazioni del browser
            l’utente può accettare, controllare o eventualmente disabilitare i
            cookie attivi. Tuttavia occorre sapere che la disattivazione dei
            cookie potrà limitare l’esperienza di navigazione del sito{" "}
          </span>
          <a href="www.accendilucegas.it">www.accendilucegas.it</a>
          <span>
            . Di seguito sono presenti i link alle impostazioni dei browser
            principali per gestire i cookie:
          </span>
          <br />
          <br />
          <ul>
            <li>
              InternetExplorer <br />{" "}
              <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">
                http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11
              </a>
            </li>
            <li>
              GoogleChrome <br />
              <a href="https://support.google.com/accounts/answer/61416?hl=it">
                https://support.google.com/accounts/answer/61416?hl=it
              </a>
            </li>
            <li>
              MozillaFirefox <br />
              <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"></a>
            </li>
            <li>
              Safari <br />
              <a href="">
                https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=en_US
              </a>
            </li>
          </ul>
          <h6>
            Segue una tabella con il dettaglio dei cookie utilizzati, e infine
            un ulteriore dettaglio e illustrazione, con le modalità per
            approfondire il tema e per disabilitare i vari cookie.
          </h6>
          <br />
          <br />
          <h5>NECESSARI</h5>
          <br />
          <p>
            I cookie necessari aiutano a contribuire a rendere fruibile un sito
            web abilitando le funzioni di base come la navigazione della pagina
            e l’accesso alle aree protette del sito. Il sito web non può
            funzionare correttamente senza questi cookie.
          </p>
          <table>
            <thead>
              <tr className={style.head_tab}>
                <td>
                  <p>
                    <strong>Nome</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Fornitore</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scopo</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scadenza</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Tipo</b>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className={style.body_tab}>
                <td>CookieConsent</td>
                <td>accendilucegas.com</td>
                <td>
                  Memorizza lo stato del consenso ai cookie dell’utente per il
                  dominio corrente.
                </td>
                <td> 1 anno</td>
                <td>HTTP</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h5>MARKETING</h5>
          <br />
          <p>
            I cookie per il marketing vengono utilizzati per monitorare i
            visitatori nei siti web. L’intento è quello di visualizzare annunci
            pertinenti e coinvolgenti per il singolo utente e quindi quelli di
            maggior valore per gli editori e gli inserzionisti terzi.
          </p>
          <table>
            <thead>
              <tr className={style.head_tab}>
                <td>
                  <p>
                    <b>Nome</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Fornitore</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scopo</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scadenza</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Tipo</b>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className={style.body_tab}>
                <td>cm/b/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>cm/g/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>cm/index/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>cm/n/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>cm/o/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>cm/triplelift/out</td>
                <td>d.adroll.com</td>
                <td>
                  Utilizzato per presentare al visitatore contenuti e pubblicità
                  pertinenti: il servizio è fornito da hub di pubblicità di
                  terze parti, che facilitano le offerte in tempo reale per gli
                  inserzionisti.
                </td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
              <tr className={style.body_tab}>
                <td>rc::c</td>
                <td>google.com</td>
                <td>
                  Utilizzato nel contesto del video-annuncio. Il cookie limita
                  il numero di volte in cui un visitatore visualizza lo stesso
                  contenuto dell’annuncio. Il cookie viene anche utilizzato per
                  garantire la pertinenza del video-annuncio al visitatore
                  specifico.
                </td>
                <td>Session</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-cast-installed</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Session</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-connected-devices</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Persistent</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-device-id</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Persistent</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-fast-check-period</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Session</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-session-app</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Session</td>
                <td>HTML</td>
              </tr>
              <tr className={style.body_tab}>
                <td>yt-remote-session-name</td>
                <td>youtube.com</td>
                <td>
                  Memorizza le preferenze del lettore video dell’utente usando
                  il video YouTube incorporato.
                </td>
                <td>Session</td>
                <td>HTML</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h5>NON CLASSIFICATI</h5>
          <br />
          <p>
            I cookie non classificati sono i cookie che sono in fase di
            classificazione, insieme ai fornitori di cookie individuali.
          </p>
          <table>
            <thead>
              <tr className={style.head_tab}>
                <td>
                  <p>
                    <b>Nome</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Fornitore</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scopo</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Scadenza</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Tipo</b>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className={style.body_tab}>
                <td>cm/x/out</td>
                <td>d.adroll.com</td>
                <td>Non classificati</td>
                <td>Session</td>
                <td>Pixel</td>
              </tr>
            </tbody>
          </table>
          <br />
          <hr /> <br />
          <h4>WEB ANALYTICS</h4> <br />
          <br />
          <h5>GOOGLE ANALYTICS</h5>
          <br />
          <p>
            La visione dei dati di traffico utenti è aggregata e quindi non
            permette ad Alleanza luce & gas l’identificazione ad personam,
            tuttavia il funzionamento di Google Analytics prevede che il cliente
            installi un cookie sul proprio sito: Google ha però visione dei Dati
            Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo
            del suddetto Sito Web e può condividerli con gli altri servizi
            sviluppati da Google ovvero utilizzare i Dati Personali per
            contestualizzare e personalizzare gli annunci del proprio network
            pubblicitario.
          </p>
          <hr /> <br />
          <h5>FACEBOOK INSIGHTS</h5>
          <br />
          <p>
            La sezione Insights fornisce informazioni sulle prestazioni della
            Pagina Facebook, come i dati demografici del pubblico e il modo in
            cui le persone rispondono ai post pubblicati.
          </p>
          <p>
            Le metriche per i post della Pagina Facebook sono disponibili non
            appena un post viene pubblicato. Nella sezione Insights, si possono
            visualizzare i seguenti dati:
          </p>
          <ul>
            <li>
              il numero di persone raggiunte dai post e il numero di quelle che
              hanno interagito con essi;
            </li>
            <li>
              il numero di persone che hanno chiamato l’azienda dalla Pagina;
            </li>
            <li>
              il numero di volte in cui si è risposto ai clienti e il tempo di
              risposta medio;
            </li>
            <li>
              il numero di persone che si sono registrate presso l’azienda nei
              loro post.
            </li>
          </ul>
          <p>
            Inoltre sono disponibili anche i dati relativi ai contenuti
            pubblicati come:
          </p>
          <ul>
            <li>
              copertura (quante persone hanno visualizzato i post e quante hanno
              messo “Mi piace” ad essi, li hanno condivisi e commentati);
            </li>
            <li>
              visualizzazioni della Pagina (quante persone visualizzano la tua
              Pagina e quali sezioni visitano);
            </li>
            <li>
              azioni sulla Pagina (quante persone accedono al sito Web del
              cliente da Facebook o cliccano sul pulsante di call to action);
            </li>
            <li>
              post (numero di persone raggiunte da ogni post, il numero di clic
              e reazioni);
            </li>
            <li>
              persone (informazioni aggregate, tra cui età, genere, lingua e
              luogo di provenienza)
            </li>
          </ul>
          <p>
            I dati di Insights per tutti i post della Pagina Facebook sono
            disponibili dopo il 19 luglio 2011 e si possono scaricare i dati sui
            post della Pagina per gli ultimi 180 giorni.
          </p>
          <p>
            E’ possibile monitorare le metriche delle inserzioni di Facebook
            tramite Gestione inserzioni o Power Editor. A seconda dell’obiettivo
            scelto, si possono visualizzare diversi risultati disponibili:
          </p>
          <ul>
            <li>interazioni ricevute;</li>
            <li>visualizzazioni;</li>
            <li>click;</li>
            <li>conversioni;</li>
            <li>copertura totale;</li>
            <li>budget speso;</li>
            <li>costo per risultato.</li>
          </ul>
          <p>
            L’obiettivo di Facebook è quello di offrire pubblicità e altri
            contenuti commerciali o contenuti sponsorizzati preziosi per gli
            utenti e per gli inserzionisti. A tal fine, gli utenti accettano
            quanto segue:
          </p>
          <ul>
            <li>
              Gli utenti forniscono a Facebook l&rsquo;autorizzazione a
              utilizzare il loro nome, l&rsquo;immagine del profilo, i contenuti
              e le informazioni in relazione a contenuti commerciali,
              sponsorizzati o correlati (ad es. i brand preferiti) pubblicati o
              supportati da Facebook. Tale affermazione implica, ad esempio, che
              l&rsquo;utente consente a un&rsquo;azienda o a un&rsquo;altra
              entit&agrave; di offrire un compenso in denaro a Facebook per
              mostrare il nome e/o l&rsquo;immagine del profilo di Facebook
              dell&rsquo;utente con i suoi contenuti o le sue informazioni senza
              ricevere nessuna compensazione. Se l&rsquo;utente ha selezionato
              un pubblico specifico per i propri contenuti o informazioni,
              rispetteremo la sua scelta al momento dell&rsquo;utilizzo.
              <br />{" "}
            </li>
            <li>
              Facebook non fornisce agli inserzionisti le informazioni o i
              contenuti degli utenti senza il consenso di questi ultimi.
            </li>
          </ul>
          <p>
            Facebook collabora con aziende di terzi che aiutano a fornire e
            migliorare i Servizi o che usano pubblicit&agrave; o prodotti
            correlati, grazie ai quali pu&ograve; offrire servizi gratuiti alle
            persone di tutto il mondo.
          </p>
          <p>
            Ecco i tipi di terzi con cui Facebook condivide le informazioni:
          </p>
          <ul>
            <li>
              Servizi pubblicitari, di misurazione e analisi (solo informazioni
              non personali)
              <br /> Facebook non condivide le informazioni personali degli
              utenti (le informazioni personali comprendono nome o indirizzo
              e-mail che &egrave; possibile usare per contattarti o
              identificarti) con i partner pubblicitari, di misurazione o
              analisi, a meno che l&rsquo;utente non conceda
              l&rsquo;autorizzazione. Facebook pu&ograve; fornire ai partner
              informazioni sulla copertura e sull&rsquo;efficacia delle
              pubblicit&agrave; senza condividere le informazioni personali o
              dopo averle aggregate in modo che non sia possibile identificarti.
              Ad esempio, Facebook pu&ograve; fornire a un inserzionista
              informazioni sulle prestazioni delle inserzioni o sul numero di
              persone che le hanno visualizzate o che hanno installato
              un&rsquo;app dopo aver visto un&rsquo;inserzione oppure fornire ai
              partner informazioni demografiche non personali (ad esempio, donna
              di 25 anni, che vive a Madrid e a cui piace la progettazione di
              software) per aiutarli a capire pubblico o clienti, purch&eacute;
              l&rsquo;inserzionista abbia accettato di rispettare le nostre
              linee guida sulle pubblicit&agrave; (
              <a
                href="https://www.facebook.com/policies/ads/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/policies/ads/
              </a>
              )<br />{" "}
            </li>
            <li>
              Fornitori, fornitori di servizi e altri partner
              <br /> Facebook trasferisce le informazioni ai fornitori,
              fornitori di servizi e altri partner che supportano
              l&rsquo;azienda a livello globale, ad esempio fornendo servizi di
              infrastruttura tecnica, analizzando come vengono usati i suoi
              Servizi, misurando l&rsquo;efficacia di inserzioni e servizi,
              fornendo assistenza ai clienti, agevolando i pagamenti o
              conducendo ricerche accademiche e sondaggi. Tali partner devono
              rispettare gli obblighi di riservatezza senza contravvenire alla
              presente Normativa sui dati e agli accordi che stipuliamo con
              loro.
              <br />{" "}
            </li>
          </ul>
          <p>
            Facebook tratta con estrema seriet&agrave; le questioni relative
            alla sicurezza, soprattutto per quanto riguarda i bambini. Per
            maggiori informazioni, visita il nostro Centro per la sicurezza (
            <a
              href="https://www.facebook.com/safety"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/safety
            </a>
            ). Per tutelare i minorenni, Facebook ha predisposto speciali misure
            di salvaguardia (ad esempio ponendo delle limitazioni sulla
            possibilit&agrave; di condivisione e connessione con i minorenni da
            parte degli adulti), pur riconoscendo che questo possa limitare
            l&rsquo;esperienza degli utenti minorenni su Facebook.
          </p>
          <p>
            A questo link{" "}
            <a
              href="https://www.facebook.com/privacy/explanation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/privacy/explanation
            </a>{" "}
            &egrave; presente la Normativa sulla Privacy completa adottata da
            Facebook.{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>INSTAGRAM INSIGHT</strong>
          </p>
          <p>
            I dati insights di Instagram forniscono informazioni su chi sono i
            follower, sul momento in cui sono online e molto altro. Si possono
            anche visualizzare dati statistici per post e storie specifici che
            sono state pubblicate dall&rsquo;account per vedere le prestazioni
            di ognuno e la modalit&agrave; di interazione delle persone con
            essi.{" "}
          </p>
          <p>Dati insights disponibili per l&rsquo;account:</p>
          <ul>
            <li>
              impression: il numero di volte in cui le inserzioni sono state
              visualizzate;
            </li>
            <li>
              copertura: il numero di account unici che hanno visualizzato i
              post e le stories;
            </li>
            <li>
              clic sul sito web: il numero di clic ai link che sono inclusi
              nella descrizione dell&rsquo;account;
            </li>
            <li>
              visualizzazioni del profilo: il numero di account unici che hanno
              visitato l&rsquo;account;
            </li>
            <li>
              informazioni sui follower: numero di persone che seguono
              l&rsquo;account, genere, et&agrave; e luogo di provenienza.
            </li>
          </ul>
          <p>Dati insights disponibili per i post:</p>
          <ul>
            <li>mi piace;</li>
            <li>commenti;</li>
            <li>
              salvati: il numero di account unici che hanno salvato il post;
            </li>
            <li>
              % di account che ha visto il post, ma non segue l&rsquo;account
            </li>
            <li>
              copertura: il numero di account unici che hanno visto il post;
            </li>
            <li>
              impression: il numero totale di volte in cui il post &egrave;
              stato visto.
            </li>
          </ul>
          <p>Dati insights disponibili per le stories:</p>
          <ul>
            <li>
              visualizzazioni: il numero di volte in cui il contenuto
              multimediale delle storie &egrave; stato visualizzato;
            </li>
            <li>
              copertura: il numero di account unici che hanno visto la storia;
            </li>
            <li>
              tocchi per andare avanti: il numero di volte che qualcuno ha
              toccato per saltare al successivo contenuto multimediale delle
              storie;
            </li>
            <li>
              tocchi per andare indietro: il numero di volte che qualcuno ha
              toccato per tornare al precedente contenuto multimediale delle
              storie;
            </li>
            <li>
              risposte: il numero di volte in cui le persone inviano messaggi
              tramite l&rsquo;opzione Invia un messaggio nella storia;
            </li>
            <li>
              scorrimento: il numero di volte che qualcuno scorre per passare
              alla storia successiva;
            </li>
            <li>
              uscite: il numero di volte che qualcuno ha abbandonato la
              visualizzazione delle storie per tornare al feed.
            </li>
          </ul>
          <p>
            Per pubblicare le inserzioni pubblicitarie su Instagram, occorre che
            l&rsquo;account sia connesso a una Pagina Facebook. E&rsquo;
            possibile monitorare le metriche delle inserzioni di Instagram
            tramite Gestione inserzioni o Power Editor (lo stesso pannello
            advertising di Facebook).
            <br /> A seconda dell&rsquo;obiettivo scelto, si possono
            visualizzare diversi risultati disponibili:
          </p>
          <ul>
            <li>interazioni ricevute; </li>
            <li>visualizzazioni;</li>
            <li>click;</li>
            <li>conversioni;</li>
            <li>copertura totale;</li>
            <li>budget speso;</li>
            <li>
              costo per risultato.
              <br />{" "}
            </li>
          </ul>
          <p>
            Instragram non affitta n&eacute; vende le informazioni
            dell&rsquo;utente a terzi al di fuori di Instagram (o del gruppo di
            aziende di cui fa parte Instagram &ndash; vedi Facebook) senza il
            consenso dell&rsquo;utente, salvo se diversamente indicato nella
            presente Normativa:
            <br />{" "}
          </p>
          <ul>
            <li>
              Instagram pu&ograve; condividere i Contenuti dell&rsquo;utente e
              le informazioni dell&rsquo;utente (inclusi, a titolo
              esemplificativo e non esaustivo, informazioni provenienti da
              cookie, file di registro, identificativi dei dispositivi, dati sui
              luoghi e dati sull&rsquo;utilizzo) con aziende che fanno
              legalmente parte dello stesso gruppo che comprende Instagram o che
              diventano parte di tale gruppo (&ldquo;Affiliate&rdquo;). Le
              Affiliate possono usare queste informazioni per aiutare a fornire,
              comprendere e migliorare i Servizi (anche fornendo i dati
              analitici) e i servizi stessi delle Affiliate (anche offrendo agli
              utenti esperienze migliori e pi&ugrave; pertinenti). Tuttavia,
              queste Affiliate rispetteranno le scelte dell&rsquo;utente in
              merito a chi pu&ograve; vedere le sue foto.
              <br />{" "}
            </li>
            <li>
              Instagram pu&ograve; condividere le informazioni
              dell&rsquo;utente, nonch&eacute; le informazioni provenienti da
              strumenti quali cookie, file di registro, identificativi dei
              dispositivi e dati sui luoghi, con organizzazioni di terzi che ci
              aiutano a fornire i Servizi agli utenti (&ldquo;Fornitori di
              servizi&rdquo;). I Fornitori di servizi otterranno l&rsquo;accesso
              alle informazioni dell&rsquo;utente per quanto ragionevolmente
              necessario a fornire i Servizi stessi, in base a ragionevoli
              termini di riservatezza.
            </li>
            <li>
              Instagram pu&ograve; condividere alcune informazioni, ad esempio i
              dati dei cookie, con partner pubblicitari terzi. Queste
              informazioni consentiranno a reti di nserzioni di terzi di
              offrire, tra le altre cose, pubblicit&agrave; mirate che, a loro
              avviso, possano essere di interesse per l&rsquo;utente.
            </li>
            <li>
              Instagram pu&ograve; rimuovere porzioni di dati che consentono di
              identificare l&rsquo;utente e condividere i dati resi anonimi con
              altre parti. Inoltre, possiamo combinare le informazioni
              dell&rsquo;utente con altre informazioni in modo che non siano
              pi&ugrave; associate all&rsquo;utente e condividere tali
              informazioni in forma aggregata.
            </li>
          </ul>
          <p>
            {" "}
            Instagram non raccoglie n&eacute; richiede in modo consapevole
            informazioni da utenti di et&agrave; inferiore ai 13 anni, n&eacute;
            consente consapevolmente a tali utenti di registrarsi ai Servizi. I
            Servizi e i relativi contenuti non sono rivolti ai bambini al di
            sotto dei 13 anni.{" "}
          </p>
          <p>
            A questo link{" "}
            <a
              href="https://help.instagram.com/155833707900388"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://help.instagram.com/155833707900388
            </a>{" "}
            &egrave; presente la Normativa sulla Privacy completa adottata da
            Instagram.{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>LINKEDIN CORPORATE PAGE</strong>
          </p>
          <p>
            I dati insights disponibili per ogni account LinkedIn si suddividono
            in questi tre blocchi:
          </p>
          <ul>
            <li>
              aggiornamenti (dove sono presenti tutti i numeri relativi ai post
              pubblicati come visualizzazioni, interazioni, interesse generato);
            </li>
            <li>
              follower (riporta tutte le informazioni relative a chi segue la
              vostra pagina come dati demografici aggregati e numero di follower
              nel tempo);
            </li>
            <li>
              visitatori (ovvero chi materialmente ha visitato la vostra pagina
              nell&rsquo;ultimo periodo e comprendono visualizzazioni
              dell&rsquo;account e dati demografici aggregati dei visitatori).
              <br />{" "}
            </li>
          </ul>
          <p>
            Per gli aggiornamenti, follower e visitatori si possono visualizzare
            i dati dell&rsquo;ultimo anno di attivit&agrave; online.
          </p>
          <p>
            Per visualizzare le insights derivanti dall&rsquo;attivazione di
            campagne advertising su LinkedIn occorre aprire il pannello LinkedIn
            Campaign Manager.
            <br /> A seconda dell&rsquo;obiettivo scelto, si possono
            visualizzare diversi risultati disponibili:
            <br />{" "}
          </p>
          <ul>
            <li>
              numero di visualizzazioni, clic e interazioni social ottenuti dai
              tuoi contenuti sponsorizzati;
            </li>
            <li>costo per clic;</li>
            <li>CTR;</li>
            <li>budget investito.</li>
          </ul>
          <p>
            Ogni account LinkedIn &egrave; interamente visibile a tutti i Membri
            e ai clienti dei Servizi di LinkedIn.
            <br /> Quando si condivide un post (es. un aggiornamento, un video o
            un blog), l&rsquo;impostazione predefinita (che si pu&ograve;
            modificare) &egrave; la condivisione pubblica. Altre persone che non
            rientrano nei propri collegamenti potranno trovare (anche tramite
            motori di ricerca) e visualizzare il post.
          </p>
          <p>
            Quando si aggiunge un consiglio, un commento su un post di terzi o
            condivide tale post, altri lo vedranno, inclusa la persona che ha
            pubblicato il post.
            <br /> In un gruppo, i post sono visibili agli altri membri del
            gruppo. L&rsquo;adesione ai gruppi &egrave; pubblica e fa parte del
            profilo, a meno che non si modifichino le impostazioni predefinite.
            <br /> Qualsiasi informazione condivisa tramite pagine di aziende o
            di altre organizzazioni nei nostri Servizi potr&agrave; essere
            visualizzata da tali entit&agrave; e da altri che visitano quelle
            pagine.
            <br /> Quando si segue una persona o un&rsquo;organizzazione,
            &egrave; visibile agli altri utenti e al &ldquo;titolare della
            pagina&rdquo; come follower.
          </p>
          <p>
            LinkedIn non condivide i dati personali degli utenti con nessun
            inserzionista terzo (fatta eccezione per gli indirizzi IP, nella
            misura in cui si tratti di dati personali in alcuni paesi) o con
            nessuna rete di annunci pubblicitari a fini pubblicitari senza lo
            specifico consenso. Tuttavia, cliccando su un annuncio
            pubblicitario, chi ha pubblicato l&rsquo;annuncio sapr&agrave; che
            ha visitato la pagina su cui ha cliccato. Inoltre, i partner
            pubblicitari possono associare i dati personali raccolti
            dall&rsquo;inserzionista direttamente dagli utenti con i nostri
            cookie e tecnologie simili. In questi casi, LinkedIn cerca di
            richiedere contrattualmente che gli inserzionisti ottengano un
            esplicito consenso prima di procedere in tal senso.
          </p>
          <p>
            Inoltre LinkedIn utilizza le informazioni degli utenti per produrre
            informazioni aggregate che non identificano nessuna persona. Ad
            esempio, LinkedIn pu&ograve; utilizzare i dati per generare
            statistiche sugli utenti, sulla loro professione o sul loro settore,
            sul numero di visualizzazioni degli annunci pubblicitari presentati
            o sui quali si &egrave; cliccato o sulla distribuzione demografica
            dei visitatori di un sito.
          </p>
          <p>
            A questo link{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>{" "}
            &egrave; presente la Normativa sulla Privacy completa adottata da
            LinkedIn.{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>TWITTER ACCOUNT</strong>
          </p>
          <p>
            I dati insights disponibili per ogni account Twitter si suddividono
            in questi tre blocchi:
          </p>
          <ul>
            <li>
              home account (si pu&ograve; visualizzare il riepilogo
              dell&rsquo;account Twitter, con statistiche di alto livello
              monitorate su scala mensile. Comprende anche i Tweet migliori e il
              suggerimento dei migliori influencer relativo all&rsquo;account);
            </li>
            <li>
              dashboard Interazioni Tweet (si pu&ograve; visualizzare la
              misurazione per ogni singolo Tweet pubblicato, esempio quanti
              utenti di Twitter hanno visualizzato, ritwittato, messo Mi piace e
              risposto a ogni Tweet);
            </li>
            <li>
              dashboard Segmenti di pubblico (contiene informazioni sugli utenti
              che seguono l&rsquo;account su Twitter. Si pu&ograve; monitorare
              l&rsquo;aumento dei follower nel tempo e visualizzare i dati
              demografici aggregati che li riguardano).
              <br />{" "}
            </li>
          </ul>
          <p>
            Per monitorare le insights derivanti dall&rsquo;attivazione di
            campagne advertising su Twitter occorre aprire la dashboard Twitter
            Ads che permette di visualizzare:
          </p>
          <ul>
            <li>
              visualizzazioni (indicano quante volte gli annunci vengono
              visualizzati dagli utenti di Twitter);
            </li>
            <li>
              risultati (sono le azioni legate agli obiettivi. Ad esempio: se
              l&rsquo;obiettivo sono le visite verso il sito Web, i risultati
              monitorati saranno i clic sul link);
            </li>
            <li>
              tasso di interazione (corrisponde alle visualizzazioni degli
              annunci divise per i risultati);
            </li>
            <li>
              costo per risultato (indica l&rsquo;importo che si paga in media
              per ogni azione pertinente che gli utenti intraprendono a partire
              dagli annunci).
            </li>
          </ul>
          <p>
            Twitter pu&ograve; condividere o divulgare le informazioni pubbliche
            degli utenti, tra cui le informazioni di profilo utente pubblico,
            Tweet pubblici, o le persone che l&rsquo;utente segue o che seguono
            lui. Le impostazioni della privacy e di visibilit&agrave;
            controllano se i Tweet e alcune informazioni del profilo sono rese
            pubbliche. Altre informazioni, tra cui il nome e nome utente, sono
            sempre rese pubbliche su Twitter, a meno che non venga eliminato
            l&rsquo;account.
          </p>
          <p>
            In merito agli annunci pubblicitari Twitter non utilizza il
            contenuto che viene condiviso privatamente nei messaggi diretti.
            L&rsquo;Informativa sugli annunci pubblicitari di Twitter proibisce
            agli inserzionisti di inserire pubblicit&agrave; su categorie
            sensibili, come la razza, la religione, la politica, la vita
            sessuale o la salute. Twitter &egrave; conforme ai Principi
            autoregolatori della Digital Advertising Alliance in materia di
            Pubblicit&agrave; comportamentale (indicata altres&igrave; come
            &ldquo;pubblicit&agrave; basata sugli interessi&rdquo;). Se lo si
            desidera, si pu&ograve; scegliere di disattivare la
            pubblicit&agrave; basata sugli interessi deselezionando
            l&rsquo;opzione Personalizza annunci nelle impostazioni di
            Personalizzazione e Dati.
          </p>
          <p>
            Twitter pu&ograve; ricevere informazioni dell&rsquo;utente da terze
            parti, come altri utenti di Twitter, partner (tra cui i partner per
            gli annunci) o le sue societ&agrave; affiliate. Ad esempio, altri
            utenti potrebbero condividere o divulgare informazioni su un altro
            utente, menzionandolo, condividendo una sua foto o taggandolo in una
            foto. Le impostazioni sulla privacy consentono comunque di
            controllare il tag nelle foto. I partner di Twitter per gli annunci
            e gli affiliati possono condividere con Twitter alcune informazioni
            come l&rsquo;ID cookie del browser, l&rsquo;ID del dispositivo
            mobile o l&rsquo;hash crittografico di un indirizzo e-mail,
            cos&igrave; come i dati demografici o di interesse e il contenuto
            visualizzato, o le azioni intraprese su un sito web o
            un&rsquo;applicazione.{" "}
          </p>
          <p>
            A questo link{" "}
            <a
              href="https://twitter.com/it/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/it/privacy
            </a>{" "}
            &egrave; presente la Normativa sulla Privacy completa adottata da
            Twitter.{" "}
          </p>
          <hr /> <br />
          <h4>TAGGING e TRACKING</h4>
          <br />
          <br />{" "}
          <p>
            Al fine di riuscire a stabilire l&rsquo;effettiva efficacia delle
            campagne di digital advertising, Alleanza luce &amp; gas utilizza
            degli specifici <strong>software di tracciamento</strong> che
            riescono ad attribuire le vendite effettuate su un sito ecommerce al
            singolo utente che ha visto il messaggio pubblicitario comparso su
            uno specifico circuito pubblicitario.
          </p>
          <p>
            Tali informazioni raccolte sono per noi visibili accedendo al
            software di terze parti che utilizziamo per il tracciamento.
          </p>
          <p>
            <strong>
              Software per il tracciamento utilizzato = TAG COMMANDER
            </strong>
          </p>
          <p>Informazioni sensibili visibili:</p>
          <ul>
            <li>IP = in chiaro</li>
            <li>Sales (totale ordine) = in chiaro</li>
            <li>
              Costo Nettissimo (senza iva e costi di spedizione) = in chiaro
            </li>
            <li>Product name = in chiaro</li>
            <li>Product Cost/Sales = in chiaro</li>
            <li>Paese = in chiaro</li>
            <li>Citt&agrave; = in chiaro</li>
            <li>Mail = in modo criptato</li>
            <li>FIRST_NAME = in chiaro &ndash; dato utente</li>
            <li>LAST_NAME = in chiaro &ndash; dato utente</li>
            <li>ORDER_EMAIL &rarr; in chiaro &ndash; dato utente</li>
            <li>PAYMENT_METHOD &rarr; in chiaro &ndash; dato utente</li>
            <li>TEL &rarr; in chiaro &ndash; dato utente</li>
            <li>User id &rarr; in chiaro &ndash; dato utente</li>
          </ul>
          <p>
            <strong>
              Software per il tracciamento utilizzato = GOOGLE TAG MANAGER
            </strong>
          </p>
          <p>
            Google Tag Manager gestisce una serie di tag e di regole di
            attivazione dei tag che definiscono i criteri con cui tali tag
            debbano essere messi a disposizione sul sito web. Quando un utente
            visita il sito, il sistema invia al suo browser la pi&ugrave;
            recente configurazione di tag con le istruzioni di attivazione di
            specifici tag.
          </p>
          <p>
            Google Tag Manager &egrave; quindi una soluzione che permette di
            gestire i tag del sito web del Cliente tramite un&rsquo;interfaccia.
            Di per s&eacute;, lo strumento di gestione dei tag (che distribuisce
            i tag) &egrave; un dominio privo di cookie che non acquisisce
            informazioni di identificazione personale. Lo strumento determina
            l&rsquo;attivazione di altri tag i quali possono acquisire dati a
            cui Google Tag Manager non ha accesso. Se gli utenti hanno
            disattivato l&rsquo;opzione a livello di dominio o di cookie, non
            saranno accessibili da nessun tag di monitoraggio distribuito
            tramite Google Tag Manager.
          </p>
          <p>
            Google potrebbe chiedere l&rsquo;autorizzazione a condividere alcuni
            dati del prodotto (ad esempio le informazioni dell&rsquo;account)
            con altri prodotti Google per attivare determinate funzioni, come la
            semplificazione dell&rsquo;inserimento di nuovi tag di monitoraggio
            delle conversioni di AdWords. Inoltre, i tecnici verificano
            periodicamente le informazioni sull&rsquo;utilizzo del prodotto per
            ottimizzarlo. Tuttavia, tali dati non vengono mai utilizzati per
            altri prodotti Google senza il consenso dell&rsquo;utente.
          </p>
          <p>
            A questo link{" "}
            <a
              href="https://www.google.com/intl/it/tagmanager/use-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/intl/it/tagmanager/use-policy.html
            </a>{" "}
            sono presenti le norme sull&rsquo;utilizzo di Google Tag Manager.
          </p>
          <hr /> <br />
          <br />
          <h4>
            <strong>ADSERVING</strong>
          </h4>
          <br />
          <p>
            <strong>ADFORM</strong>
          </p>
          <p>
            Al fine di creare e diffondere campagne advertising online Alleanza
            luce &amp; gas utilizza la piattaforma AdForm che consente di
            svolgere due funzioni base:
          </p>
          <p>
            <strong>1) Servire la pubblicit&agrave; online</strong>
          </p>
          <p>
            L&rsquo;Ad Server{" "}
            <strong>
              &ldquo;trasporta&rdquo; un annuncio (una creativit&agrave; banner)
              dal Server fino all&rsquo;Utente
            </strong>{" "}
            (generando una &ldquo;impression&rdquo;), per conto di un
            Inserzionista (azienda cliente), in una specifica posizione della
            pagina che l&rsquo;Utente sta visitando. Nell&rsquo;Ad Server si
            definiscono i{" "}
            <strong>target da raggiungere e gli spazi nei siti</strong>{" "}
            (posizionamenti) da utilizzare, la frequenza di esposizione degli
            annunci e altri importanti parametri di una Campagna Pubblicitaria
            Online.{" "}
          </p>
          <p>
            <strong>2)</strong> <strong>Tracciare e contare</strong>
          </p>
          <p>
            L&rsquo;Ad Server si occupa di{" "}
            <strong>tracciare e contare impression, click e interazioni</strong>{" "}
            generate dopo la consegna dei banner sul sito ospitante (delivery) e
            quello che succede dopo, cio&egrave; le <strong>performance</strong>{" "}
            della pubblicit&agrave; online (conversioni) sul sito
            dell&rsquo;Inserzionista.
          </p>
          <p>
            In entrambe le funzioni Adform non raccoglie dati che rivelano
            l&rsquo;identit&agrave; degli utenti e si attiene in maniera
            stringente alle regole di privacy online e agli standard di
            sicurezza.
          </p>
          <p>
            Ha implementato infatti tecnologie per monitorare i materiali
            pubblicitari ed evitare la diffusione di contenuti potenzialmente
            non sicuri e che possano violare le regole delle privacy.{" "}
          </p>
          <p>
            I dati che vengono raccolti e immagazzinati da AdForm sono in forma
            anonima, ovvero si tratta di dati che non rivelano
            l&rsquo;identit&agrave; degli utenti e che possono essere usati per
            funzioni analitiche circa il loro comportamento online. Questo
            permette di migliorare l&rsquo;esperienza di navigazione online e di
            diffondere contenuti pubblicitari il pi&ugrave; possibile
            d&rsquo;interesse e di rilievo per gli utenti online.
          </p>
          <p>
            Nello specifico i cookie che vengono raccolti hanno la
            finalit&agrave; di:
          </p>
          <ul>
            <li>
              identificare se il browser utilizzato dall&rsquo;utente accetta i
              cookie (cookie di tipo C)
            </li>
            <li>
              identificare se il browser utilizzato dall&rsquo;utente accetta
              cookie di terze parti (cookie di tipo TPC)
            </li>
            <li>
              identificare se &egrave; necessario ricontrollare
              l&rsquo;esistenza della corrispondenza dei cookie del partner
              (cookie di tipo GCM)
            </li>
            <li>disattivare il token di sicurezza (cookie di tipo token)</li>
            <li>
              controllare dell&rsquo;opt-out specifico dell&rsquo;inserzionista
              (cookie di tipo otsid)
            </li>
            <li>
              identificare in modo unico l&rsquo;utente (cookie di tipo uid)
            </li>
            <li>
              identificare in modo unico il cookie dell&rsquo;utente (cookie di
              tipo cid &ndash; uguale al tipo uid)
            </li>
            <li>
              ottenere informazioni sulla diffusione delle creativit&agrave;
              (impression totali, impression giornaliere, click totali, click
              giornalieri e la data dell&rsquo;ultima impression) (cookie di
              tipo SR&lt;RotarID&gt;)
            </li>
            <li>
              identificare l&rsquo;ultimo click per i pixel di terze parti nel
              sito del cliente (cookie di tipo CT&lt;TrackingSetupID&gt;)
            </li>
            <li>
              identificare il limite di frequenza giornaliera per banner
              espandibili (cookie di tipo EBFCD&lt;BannerID&gt;)
            </li>
            <li>
              identificare il limite di frequenza personalizzato per banner
              espandibili (cookie di tipo CFFC&lt;TagID&gt;)
            </li>
          </ul>
          <p>
            Per maggiori informazioni consultare il seguente link:{" "}
            <a
              href="http://site.adform.com/privacy-policy/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://site.adform.com/privacy-policy/en/
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>DOUBLECLICK</strong>
          </p>
          <p>
            Per le campagne advertising online Alleanza luce &amp; gas
            pu&ograve; utilizzare anche la piattaforma Doubleclick che consente
            di creare, gestire e sviluppare campagne di digital advertising.
            DoubleClick offre soluzioni per la gestione dello spazio
            pubblicitario e la pubblicazione di annunci.
          </p>
          <p>
            L&rsquo;Ad Server &ldquo;trasporta&rdquo; un annuncio (una
            creativit&agrave; banner) dal Server fino all&rsquo;Utente
            (generando una &ldquo;impression&rdquo;), per conto di un
            Inserzionista (azienda cliente), in una specifica posizione della
            pagina che l&rsquo;Utente sta visitando. Nell&rsquo;Ad Server si
            definiscono i target da raggiungere e gli spazi nei siti
            (posizionamenti) da utilizzare, la frequenza di esposizione degli
            annunci e altri importanti parametri di una Campagna Pubblicitaria
            Online.
          </p>
          <p>
            DoubleClick Ad Exchange (AdX)
            <br /> Si tratta di una piattaforma tecnologica che facilita la
            compravendita in tempo reale di inventari da molteplici network
            pubblicitari. DoubleClick Ad Exchange collega reti pubblicitarie,
            agenzie e piattaforme DSP di terze parti con un alto numero di spazi
            pubblicitari globali in tempo reale.
          </p>
          <p>
            Google utilizza un cookie per la pubblicazione degli annunci sui
            siti che partecipano al programma Ad Exchange, per ulteriori
            informazioni sui cookie utilizzati fare riferimento al paragrafo
            inerenti i cookie utilizzati genericamente da Google.
          </p>
          <hr /> <br />
          <br />
          <h4>
            <strong>ADVERTISING TOOL</strong>
          </h4>
          <br />
          <p>
            <strong>DOUBLECLICK FOR SEARCH</strong>
          </p>
          <p>
            Al fine di organizzare efficacemente le campagne advertising su rete
            Search, Alleanza luce &amp; gas utilizza la piattaforma DoubleClick
            Search, che consente di gestire la creazione di annunci
            simultaneamente su pi&ugrave; motori di ricerca e canali
            multimediali.
          </p>
          <p>
            La visione dei dati relativi all&rsquo;interazione degli utenti con
            gli annunci &egrave; aggregata e quindi non permette a Alleanza luce
            &amp; gas la visione di informazioni personali, tuttavia il
            funzionamento di DoubleClick si basa sull&rsquo;utilizzo di cookie
            per migliorare il rendimento delle campagne.
            <br /> I cookie possono essere utilizzati per ottimizzare la scelta
            del target della pubblicit&agrave; in base a ci&ograve; che &egrave;
            pertinente per un utente e per la personalizzazione degli annunci.
          </p>
          <p>
            DoubleClick invia un cookie al browser dopo ogni impressione, clic o
            altra attivit&agrave; che comporta una chiamata ai nostri server. Se
            il browser accetta il cookie, quest&rsquo;ultimo viene memorizzato
            sul browser.
          </p>
          <p>
            <a
              href="https://policies.google.com/technologies/types"
              target="_blank"
              rel="noopener noreferrer"
            >
              A questo link &egrave; possibile visualizzare la tipologia di
              cookie utilizzati da DoubleClick
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>GOOGLE ADWORDS</strong>
          </p>
          <p>
            Alleanza luce &amp; gas utilizza la piattaforma Google Adwords, allo
            scopo di gestire la creazione di annunci testuali e campagne display
            e video.
          </p>
          <p>
            La visione dei dati relativi all&rsquo;interazione degli utenti con
            gli annunci &egrave; aggregata e quindi non permette a Alleanza luce
            &amp; gas la visione di informazioni personali, tuttavia il
            funzionamento di Google Adwords si basa sull&rsquo;utilizzo di
            cookie per migliorare il rendimento delle campagne.
          </p>
          <p>
            Per approfondimenti sui cookie trattati da Google Adwords e sulle
            modalit&agrave; di trattamento dei dati, si rimanda al paragrafo
            Google.
            <br />{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>ADESPRESSO</strong>
          </p>
          <p>
            Per la realizzazione di campagne su Facebook, Alleanza luce &amp;
            gas pu&ograve; utilizzare il Software AdEspresso: un gestore di
            inserzioni Facebook che consente la realizzazione simultanea di
            molteplici declinazioni dello stesso annuncio pubblicitario, al fine
            di valutarne il rendimento su molteplici microtarget.
          </p>
          <p>
            AdEspresso utilizza i cookie per localizzare il dispositivo e
            identificare il browser utilizzato, oltre che per acquisire
            informazioni sul comportamento di navigazione degli utenti in rete.
            AdEspresso consente di indirizzare gli annunci pubblicitari verso
            specifici utenti sulla base delle informazioni anagrafiche raccolte.
          </p>
          <p>
            Per ulteriori informazioni sulla privacy policy e sulle
            modalit&agrave; di gestione dei cookie fare fiderimento al seguente
            link:{" "}
            <a
              href="https://adespresso.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://adespresso.com/privacy/
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>AUDIENCE TARGETING</strong>
          </p>
          <p>
            Con il termine <strong>Audience Targeting</strong> vengono
            identificate tutte quelle attivit&agrave; digitali che permettono di
            identificare un pubblico a cui rivolgere i messaggi promozionali
            partendo da banche dati di propriet&agrave; del cliente.
          </p>
          <p>
            Un esempio di fornitore che utilizza le attivit&agrave; di Audience
            Targeting &egrave; <strong>Google</strong>.
          </p>
          <p>
            Un prodotto pubblicitario di Google che utilizza il meccanismo
            dell&rsquo;Audience Targeting &egrave; il{" "}
            <strong>Customer Match</strong>.
          </p>
          <p>
            Il cliente di Alleanza luce &amp; gas (es. un sito ecommerce)
            fornisce la banca dati degli indirizzi email dei propri clienti
            cos&igrave; che Alleanza luce &amp; gas possa caricarla sui sistemi
            informatici di Google.
          </p>
          <p>
            Google identifica gli indirizzi @gmail.com presenti in questa lista
            e identifica quindi un <strong>pubblico personalizzato</strong> di
            cui analizza le caratteristiche comuni di navigazione web (es.
            interessati all&rsquo;argomento finanza e cucina). In tal modo viene
            identificato un <strong>pubblico simile</strong> a cui mostrare la i
            messaggi pubblicitari
          </p>
          <ul>
            <li>
              l&rsquo;assunto &egrave; che se molti dei clienti dei quello
              specifico ecommerce sono interessati anche ai siti di finanza e
              cucina allora &egrave; altamente probabile che tutti i navigatori
              web che sono interessati ai siti di finanza e cucina potrebbero
              essere potenzialmente interessati anche ai prodotti del sito
              ecommerce)
            </li>
          </ul>
          <p>
            <a
              href="https://support.google.com/adwords/answer/6334160"
              target="_blank"
              rel="noopener noreferrer"
            >
              qui puoi trovare in che modo google utilizza i dati customer match
            </a>
          </p>
          <p>
            Un prodotto pubblicitario di Google che utilizza il meccanismo
            dell&rsquo;Audience Targeting &egrave; il{" "}
            <strong>Similar Audience </strong>(su base cookie).
          </p>
          <p>
            Un esempio di fornitore che utilizza le attivit&agrave; di Audience
            Targeting &egrave; <strong>Facebook</strong>.
          </p>
          <p>
            Due prodotti pubblicitari di Facebook che utilizzano il meccanismo
            dell&rsquo;Audience Targeting sono il{" "}
            <strong>Custom Audience</strong> e il <strong>Look a Like.</strong>
          </p>
          <p>
            Il cliente di Alleanza luce &amp; gas (es. un sito ecommerce)
            fornisce la banca dati degli indirizzi email dei propri clienti (o
            installa sul proprio sito un cookie che raccoglie i dati
            direttamente per Facebook) cos&igrave; che Alleanza luce &amp; gas
            possa caricarla sui sistemi informatici di Facebook.
          </p>
          <p>
            Facebook identifica gli indirizzi email presenti in questa lista che
            sono stati usati per iscriversi ad un profilo Facebook. Questo
            sottoinsieme di profili Facebook cos&igrave; identificati &egrave;
            definito pubblico personalizzato o <strong>Custom Audience </strong>
            e pu&ograve; essere utilizzato come pubblico su cui veicolare
            messaggi promozionali dello specifico sito ecommerce.
          </p>
          <p>
            Facebook inoltre, partendo da questa Custom Audience (o pubblico
            personalizzato<strong>)</strong> pu&ograve; analizzare le
            caratteristiche comuni di interesse espresse sulla propria
            piattaforma (es. hanno fatto Like a Pagine Facebook di prodotti o
            aziende in ambito finanza e cucina). In tal modo viene identificato
            un pubblico simile o (<strong>Look a Like</strong>) a cui mostrare
            la i messaggi pubblicitari
          </p>
          <ul>
            <li>
              l&rsquo;assunto &egrave; che se molti dei profili facebook dei
              clienti di quello specifico ecommerce sono fan di pagine facebook
              inerenti i temi di finanza e cucina allora &egrave; altamente
              probabile che tutti i profili facebook che sono fan di pagine
              facebook inerenti i temi di finanza e cucina potrebbero essere
              potenzialmente interessati anche ai prodotti del sito ecommerce).
            </li>
          </ul>
          <p>
            <a
              href="https://www.facebook.com/privacy/explanation"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/privacy/explanation
            </a>
          </p>
          <hr /> <br />
          <h4>
            <strong>ADVERTISING PLATFORM</strong>
          </h4>
          <br />
          <p>
            Al fine di gestire campagne di digital advertising, Alleanza luce
            &amp; gas utilizza degli specifiche{" "}
            <strong>piattaforme di pianificazione</strong> che erogano il
            messaggio pubblicitario su differenti circuiti pubblicitari.
          </p>
          <p>
            I dati raccolti dalle piattaforme non rivelano
            l&rsquo;identit&agrave; degli utenti e le piattaforme si attengono
            in maniera stringente alle regole di privacy online e agli standard
            di sicurezza (sono infatti implementate tecnologie per monitorare i
            materiali pubblicitari ed evitare la diffusione di contenuti
            potenzialmente non sicuri e che possano violare le regole delle
            privacy).{" "}
          </p>
          <p>
            I dati che vengono raccolti e immagazzinati sono in forma anonima,
            ovvero si tratta di dati che non rivelano l&rsquo;identit&agrave;
            degli utenti, in maniera aggregata, e che possono solo essere usati
            per funzioni analitiche circa il loro comportamento online. Questo
            permette di migliorare l&rsquo;esperienza di navigazione online e di
            diffondere contenuti pubblicitari il pi&ugrave; possibile
            d&rsquo;interesse e di rilievo per gli utenti online.
          </p>
          <hr /> <br />
          <p>
            <strong>ADFORM</strong>
          </p>
          <p>
            I dati che vengono raccolti e immagazzinati da AdForm sono in forma
            anonima, ovvero si tratta di dati che non rivelano
            l&rsquo;identit&agrave; degli utenti e che possono essere usati per
            funzioni analitiche circa il loro comportamento online. Questo
            permette di migliorare l&rsquo;esperienza di navigazione online e di
            diffondere contenuti pubblicitari il pi&ugrave; possibile
            d&rsquo;interesse e di rilievo per gli utenti online.
          </p>
          <p>
            Nello specifico i cookie che vengono raccolti hanno la
            finalit&agrave; di:
          </p>
          <ul>
            <li>
              identificare se il browser utilizzato dall&rsquo;utente accetta i
              cookie (cookie di tipo C)
            </li>
            <li>
              identificare se il browser utilizzato dall&rsquo;utente accetta
              cookie di terze parti (cookie di tipo TPC)
            </li>
            <li>
              identificare se &egrave; necessario ricontrollare
              l&rsquo;esistenza della corrispondenza dei cookie del partner
              (cookie di tipo GCM)
            </li>
            <li>disattivare il token di sicurezza (cookie di tipo token)</li>
            <li>
              controllare dell&rsquo;opt-out specifico dell&rsquo;inserzionista
              (cookie di tipo otsid)
            </li>
            <li>
              identificare in modo unico l&rsquo;utente (cookie di tipo uid)
            </li>
            <li>
              identificare in modo unico il cookie dell&rsquo;utente (cookie di
              tipo cid &ndash; uguale al tipo uid)
            </li>
            <li>
              ottenere informazioni sulla diffusione delle creativit&agrave;
              (impression totali, impression giornaliere, click totali, click
              giornalieri e la data dell&rsquo;ultima impression) (cookie di
              tipo SR&lt;RotarID&gt;)
            </li>
            <li>
              identificare l&rsquo;ultimo click per i pixel di terze parti nel
              sito del cliente (cookie di tipo CT&lt;TrackingSetupID&gt;)
            </li>
            <li>
              identificare il limite di frequenza giornaliera per banner
              espandibili (cookie di tipo EBFCD&lt;BannerID&gt;)
            </li>
            <li>
              identificare il limite di frequenza personalizzato per banner
              espandibili (cookie di tipo CFFC&lt;TagID&gt;)
            </li>
          </ul>
          <p>
            Per maggiori informazioni consultare il seguente link:{" "}
            <a
              href="http://site.adform.com/privacy-policy/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://site.adform.com/privacy-policy/en/
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>ROCKETFUEL</strong>
          </p>
          <p>Le informazioni raccolte dalla piattaforma sono:</p>
          <ul>
            <li>indirizzo IP</li>
            <li>identificativo unico del dispositivo</li>
            <li>tipologia di browser</li>
            <li>lingua di utilizzo del browser</li>
            <li>
              il server sul quale &egrave; connesso il computer
              dell&rsquo;utente
            </li>
            <li>
              le informazioni sul sistema operativo del computer
              dell&rsquo;utente
            </li>
            <li>quali inserzioni vengono viste dall&rsquo;utente</li>
            <li>in quali pagine l&rsquo;utente ha visto le inserzioni</li>
            <li>
              quando l&rsquo;utente ha interagito con le inserzioni (click)
            </li>
          </ul>
          <p>
            Per identificare il gradimento delle inserzioni inoltre la
            piattaforma pu&ograve; somministrare dei questionari agli utenti in
            cui viene chiesto quale inserzione, prodotto o brand l&rsquo;utente
            gradisce o non gradisce.
          </p>
          <p>
            Inoltre per avere una conoscenza dell&rsquo;interazione avvenuta tra
            l&rsquo;utente e il sito del cliente vengono inseriti dei pixel di
            tracciamento all&rsquo;interno dei software di tracciamento (vedi
            relativo capitolo), che permettono di sapere per esempio se un
            prodotto &egrave; stato aggiunto ad un carrello di acquisto in
            seguito all&rsquo;interazione dell&rsquo;utente con
            un&rsquo;inserzione pubblicitaria.
          </p>
          <p>
            Tutte queste informazioni sono identificate in maniera anonima nel
            database della piattaforma.
          </p>
          <p>
            La piattaforma inoltre pu&ograve; condividere queste informazioni
            raccolte in maniera anonima con aziende partner, che trattano allo{" "}
          </p>
          <p>
            stesso modo le informazioni (quindi sempre in maniera anonima).{" "}
          </p>
          <p>
            I dati raccolti vengono cancellati dal database di RocketFuel dopo
            90 giorni di inattivit&agrave;.
          </p>
          <p>I dati raccolti non appartengono mai a minori di 13 anni.</p>
          <p>
            Per maggiori informazioni consultare il seguente link:{" "}
            <a
              href="http://rocketfuel.com/it/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://rocketfuel.com/it/privacy/
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>QUANTCAST</strong>
          </p>
          <p>
            Quantcast offre due prodotti principali: (1) Quantcast Measure e (2)
            Quantcast Advertise.{" "}
          </p>
          <p>
            Il prodotto Quantcast Measure &egrave; gratuito e aiuta i
            proprietari di siti Web a comprendere le caratteristiche e i dati
            demografici delle persone che visitano i loro siti.{" "}
          </p>
          <p>
            Quantcast Advertise consente alle aziende di erogare
            pubblicit&agrave; online pertinente per singoli consumatori.
            Quantcast &egrave; impegnata a rendere la pubblicit&agrave; online
            pi&ugrave; efficace e piacevole per ognuno. A questo scopo,
            l&rsquo;azienda raccoglie e analizza informazioni in forma anonima
            sugli interessi dei singoli consumatori.
          </p>
          <hr /> <br />
          <p>
            <strong>Cookie</strong>
          </p>
          <p>
            Se l&rsquo;utente visita un sito con cookie Quantcast con il browser
            impostato per accettare i cookie, Quantcast presuppone che
            l&rsquo;utente abbia acconsentito all&rsquo;impostazione e alla
            lettura dei cookie da parte di Quantcast dal browser. L&rsquo;utente
            non &egrave; obbligato a utilizzare i cookie, ma se non li accetta,
            potrebbe non essere in grado di accedere a tutte le parti di
            Quantcast.com o a tutte le funzionalit&agrave; dei Servizi. Inoltre,
            alcuni siti Web ospitano versioni precedenti del codice che in
            passato utilizzavano cookie Flash. Questi cookie non vengono
            pi&ugrave; utilizzati per alcuno scopo. Se l&rsquo;utente desidera
            eliminare i cookie Flash, dovr&agrave; accedere allo strumento di
            gestione delle impostazioni di Flash Player (disponibile nel sito
            Web di Adobe) o fare clic qui{" "}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>Cookie</strong>
          </p>
          <p>
            I Tag Quantcast sono piccolissimi elementi grafici inseriti dai
            Partner nelle proprie pagine Web e righe di codice contenute nelle
            app che creano. Nelle app questo codice viene chiamato SDK. Quando
            un utente visualizza un sito o utilizza un&rsquo;app con uno dei Tag
            Quantcast, i server Quantcast ricevono una notifica e
            l&rsquo;azienda pu&ograve; raccogliere le informazioni dal browser o
            dal dispositivo mobile.
          </p>
          <hr /> <br />
          <p>
            <strong>Dati di log</strong>
          </p>
          <p>
            I Dati di log includono (1) informazioni inviate a Quantcast dai
            browser che trovano Tag Quantcast, quali tipo di browser, ora del
            browser, data e ora di accesso, risoluzione dello schermo, indirizzo
            IP, sito URL di riferimento, sito URL corrente e stringhe di
            ricerca; e (2) informazioni ricevute dall&rsquo;SDK Quantcast
            incorporato nelle app per dispositivi mobile, che possono includere
            quelle citate sopra nonch&eacute; l&rsquo;identificatore del
            dispositivo, informazioni sulla localit&agrave;, informazioni su
            dati e utilizzo dell&rsquo;applicazione e identificatori univoci
            dell&rsquo;applicazione e dell&rsquo;installazione.
          </p>
          <p>Dati personali (PII, Personally Identifiable Information)</p>
          <p>
            I Dati personali (PII, Personally Identifiable Information) sono
            informazioni sull&rsquo;utente che possono essere utilizzate per
            contattarlo o identificarlo come individuo. I Dati personali possono
            includere: nome e cognome, nome utente, numero di telefono,
            indirizzo e-mail e indirizzo fisico.
          </p>
          <p>
            Dati non personali (Non-PII, Non-Personally Identifiable
            Information)
          </p>
          <p>
            Per Dati non personali si intendono informazioni sulle persone che,
            in alcuni casi, possono consentire l&rsquo;identificazione di un
            dispositivo o un browser, ma non di un individuo. Ad esempio, i
            seguenti vengono considerati Dati non personali: codice postale,
            localit&agrave; approssimativa (ad esempio area, citt&agrave;,
            codice postale), tipo di browser, ID cookie, identificatori dei
            dispositivi, indirizzo IP, siti Web visitati dall&rsquo;utente e
            annunci pubblicitari visualizzati o selezionati. Anche i dati
            aggregati, privati dell&rsquo;identit&agrave; dell&rsquo;utente e/o
            resi anonimi vengono considerati Dati non personali. Le
            giurisdizioni non accettano tutte la stessa definizione di Dati
            personali e Dati non personali. Alcune giurisdizioni considerano gli
            indirizzi IP Dati personali. Quando Quantcast memorizza un indirizzo
            IP, lo fa in forma troncata o crittografata.
          </p>
          <hr /> <br />
          <p>
            <strong>Informazioni raccolte dal Sito Quantcast</strong>
          </p>
          <p>
            Quando un Visitatore o un Utente registrato visita Quantcast.com, i
            server dell&rsquo;azienda registrano e raccolgono i Dati di log
            inviati automaticamente dal suo browser. I Dati di log vengono
            utilizzati da Quantcast per monitorare, analizzare, amministrare e
            migliorare il Sito e i Servizi Quantcast. Quantcast richiede ai
            Partner Quantcast di fornire alcuni Dati personali come parte
            dell&rsquo;accesso per utilizzare i prodotti Measure e Advertise,
            quali nome e cognome, e-mail di contatto, indirizzo fisico e numero
            di telefono. Quantcast utilizza questi Dati personali principalmente
            per fornire i Servizi, amministrare gli account e gestire eventuali
            domande e problemi posti dall&rsquo;utente.
          </p>
          <p>
            Se l&rsquo;utente contatta Quantcast tramite e-mail, Quantcast
            potrebbe mantenere un record delle informazioni di contatto e della
            corrispondenza. Se l&rsquo;utente fornisce informazioni di contatto,
            Quantcast potrebbe utilizzarle per contattarlo con newsletter o
            materiali di marketing o promozionali. Se in qualsiasi momento
            l&rsquo;utente decide di non voler pi&ugrave; ricevere queste
            comunicazioni, dovr&agrave; seguire le istruzioni per
            l&rsquo;annullamento della sottoscrizione disponibili in tutte le
            comunicazioni.
          </p>
          <hr /> <br />
          <p>
            <strong>Informazioni raccolte tramite i Servizi</strong>
          </p>
          <p>
            Quando l&rsquo;utente visita un&rsquo;app o un sito gestito da un
            Partner Quantcast, il Partner consente a Quantcast di raccogliere
            Dati di log (definiti sopra); Quantcast riceve i Dati non personali
            direttamente dai Partner. Le informazioni vengono associate a un
            identificatore univoco anonimo (l&rsquo;ID cookie) non al nome,
            all&rsquo;indirizzo e-mail, all&rsquo;indirizzo o al numero di
            telefono. Queste informazioni vengono utilizzate da Quantcast per
            migliorare l&rsquo;accuratezza del prodotto Measure (descritto
            sopra) e per determinare gli annunci pi&ugrave; interessanti per
            l&rsquo;utente.{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>Condivisione e divulgazione delle informazioni</strong>
          </p>
          <p>Dati personali</p>
          <p>
            Quantcast non condivide con n&eacute; divulga a terze parti i Dati
            personali degli utenti, ad eccezione di quanto richiesto
            dall&rsquo;utente o indicato di seguito nella sezione sulle
            informazioni condivise con i provider di servizi di Quantcast.
          </p>
          <p>Dati non personali</p>
          <p>
            Quantcast potrebbe condividere Dati non personali con terze parti,
            inclusi determinati Dati di log, come parte della distribuzione e
            del miglioramento dei prodotti Measure e Advertise. Ad esempio,
            Quantcast potrebbe divulgare tali dati a societ&agrave; coinvolte
            nell&rsquo;erogazione di annunci o nella visibilit&agrave; degli
            annunci. Analogamente, Quantcast potrebbe divulgare tali dati per
            rendere disponibili o semplificare la misurazione
            dell&rsquo;audience del sito, l&rsquo;analisi del traffico o
            l&rsquo;analisi demografica e per consentire ai siti Web di fornire
            ai propri inserzionisti segmenti di audience appropriati per i
            prodotti o i servizi al loro interno.
          </p>
          <p>Informazioni condivise con i provider di servizi di Quantcast</p>
          <p>
            Quantcast pu&ograve; avvalersi di terze parti e delle proprie
            societ&agrave; affiliate per l&rsquo;amministrazione e il
            miglioramento dei prodotti Measure e Advertise. Ad esempio, queste
            aziende potrebbero contribuire a hosting, elaborazione dei dati,
            gestione dei database e attivit&agrave; amministrative. Queste terze
            parti e affiliate possono avere accesso ai Dati di log e ai Dati
            personali dell&rsquo;utente (se l&rsquo;utente ha inviato tali dati
            a Quantcast), ma solo per eseguire le suddette attivit&agrave; per
            conto dell&rsquo;utente e con l&rsquo;obbligo di non divulgarli o
            utilizzarli per altri scopi.
          </p>
          <p>
            Quantcast riceve inoltre dati demografici e di report di interessi
            sul Sito Quantcast da uno dei propri provider di servizi, Google
            Analytics. Questi dati vengono utilizzati esclusivamente per
            soddisfare la curiosit&agrave; di Quantcast riguardo al tipo e alla
            qualit&agrave; di tali dati demografici e report di interessi
            forniti da Google. Se l&rsquo;utente lo desidera, pu&ograve;
            rifiutare esplicitamente l&rsquo;utilizzo di Google Analytics
            attraverso le impostazioni sugli annunci di Google.
          </p>
          <p>
            Informazioni divulgate ai fini della protezione di Quantcast e di
            altri
          </p>
          <p>
            La politica di Quantcast prevede la protezione dalle violazioni
            della privacy degli utenti attraverso l&rsquo;abuso del sistema
            legale. Tuttavia, Quantcast si riserva il diritto di divulgare
            informazioni sull&rsquo;utente a ufficiali pubblici o responsabili
            dell&rsquo;applicazione della legge o a privati qualora Quantcast, a
            propria esclusiva discrezione, lo ritenga necessario per: (i)
            rispettare la conformit&agrave; a qualsiasi legge o normativa in
            vigore e a citazioni, ordinanze giudiziarie e mandati validi; (ii)
            proteggere i diritti, la propriet&agrave; e la sicurezza di
            Quantcast stessa, di terze parti o del pubblico in generale; e (iii)
            impedire o arrestare attivit&agrave; considerate da Quantcast
            illegali o non etiche.
          </p>
          <p>
            Informazioni divulgate in caso di trasferimento di propriet&agrave;
          </p>
          <p>
            Quantcast pu&ograve; vendere, trasferire o altrimenti condividere
            alcune o tutte le proprie risorse, inclusi i Dati di log e i Dati
            personali dell&rsquo;utente, in relazione a una fusione,
            un&rsquo;acquisizione, una riorganizzazione, una vendita di risorse
            o in caso di bancarotta. Quantcast pu&ograve; anche rendere
            disponibili determinati Dati di log o Dati personali a potenziali
            investitori o acquirenti (o ai loro consulenti) anticipatamente
            rispetto al completamento di una di tali transazioni.
          </p>
          <p>Combinazione dei dati</p>
          <p>
            Quantcast non correla i Dati di log ai Dati personali
            dell&rsquo;utente di cui pu&ograve; disporre n&eacute; tenta in
            altri modi di individuare l&rsquo;identit&agrave; dell&rsquo;utente.
            Quantcast impiega appropriate misure di sicurezza tecniche e
            organizzative per mantenere sicuri i Dati di log dell&rsquo;utente e
            per evitare che vengano correlati ai Dati personali
            dell&rsquo;utente.
          </p>
          <p>Associazioni multipiattaforma</p>
          <p>
            Ai Partner Quantcast che gestiscono siti Web e app per dispositivi
            mobile Quantcast fornisce un servizio di report multipiattaforma. A
            questo scopo, Quantcast si affida a identificatori con hash (ovvero
            criptati) derivati dagli accessi degli utenti per associare
            l&rsquo;utilizzo dell&rsquo;utente su diverse piattaforme mobile e
            desktop. In questo modo, il prodotto Measure &egrave; in grado di
            fornire report significativi tra diverse piattaforme per un singolo
            Partner. Talvolta Quantcast si affida inoltre ai Dati di log per
            effettuare associazioni multipiattaforma.
          </p>
          <p>Politiche di conservazione dei dati</p>
          <p>
            Quantcast conserva i Dati di log per 13 mesi per soddisfare i
            requisiti di stagionalit&agrave;. I dati vengono quindi eliminati in
            modo permanente. Questo &egrave; il pi&ugrave; lungo periodo di
            conservazione dei Dati di log raccolti attraverso i prodotti Measure
            e Advertise.
          </p>
          <p>Rifiuto esplicito</p>
          <p>
            Qualora l&rsquo;utente preferisca non ricevere contenuto basato
            sugli interessi e gli annunci attivati da Quantcast, pu&ograve;
            rifiutare esplicitamente tali contenuti facendo clic sul
            collegamento corrispondente qui https://www.quantcast.com/opt-out/
            Quantcast &egrave; inoltre un membro della Network Advertising
            Initiative (NAI) e partecipa al programma di autoregolamentazione
            della Digital Advertising Alliance (DAA). Questi programmi
            forniscono una funzionalit&agrave; di rifiuto esplicito tramite cui
            i consumatori possono scegliere di non ricevere pubblicit&agrave;
            mirata da qualsiasi affiliata NAI o DAA. Questi collegamenti
            reindirizzano alle opzioni di rifiuto esplicito NAI
            http://optout.networkadvertising.org/#!/ e di rifiuto esplicito DAA
            http://optout.aboutads.info/#!/. Gli utenti dell&rsquo;Unione
            Europea possono scegliere di utilizzare l&rsquo;opzione di rifiuto
            esplicito EDAA http://www.youronlinechoices.eu. Dopo che
            l&rsquo;utente ha indicato il proprio rifiuto esplicito, Quantcast
            aggiunger&agrave; al suo browser un cookie che lo identifica come
            utente che ha scelto di non ricevere tali materiali. L&rsquo;utente
            non ricever&agrave; pi&ugrave; contenuto basato sugli interessi o
            annunci attivati da Quantcast e Quantcast non registrer&agrave;
            pi&ugrave; le abitudini dell&rsquo;utente in termini di visite dei
            siti Web o di utilizzo di contenuti multimediali online.
            Poich&eacute; il rifiuto esplicito &egrave; basato su cookie, il
            browser dell&rsquo;utente deve essere impostato per
            l&rsquo;accettazione di cookie di terze parti. Se l&rsquo;utente
            utilizza pi&ugrave; dispositivi o browser, dovr&agrave; impostare il
            proprio rifiuto esplicito su ciascuno di essi. Se l&rsquo;utente
            elimina il cookie, dovr&agrave; indicare nuovamente il proprio
            rifiuto esplicito.
          </p>
          <p>
            Accesso, modifica o eliminazione delle informazioni
            dell&rsquo;utente
          </p>
          <p>
            L&rsquo;utente ha il diritto di accedere ai propri Dati personali
            controllati da Quantcast scrivendo agli indirizzi e-mail pertinenti,
            identificati nella sezione &ldquo;Contatti&rdquo; di questo link:{" "}
            <a
              href="https://www.quantcast.it/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.quantcast.it/privacy/
            </a>{" "}
            Quantcast si riserva il diritto (soggetto alla legge in vigore) di
            rifiutare richieste eccessive o che ledono la privacy altrui. Un
            Utente registrato pu&ograve; esaminare, aggiornare, correggere o
            eliminare i Dati personali presenti nel profilo di registrazione
            accedendo al proprio account. I Dati di log non possono essere
            corretti o eliminati dall&rsquo;utente. Qualora desideri eliminare
            il proprio account, l&rsquo;utente dovr&agrave; contattare
            Quantcast. Quantcast potrebbe mantenere una copia archiviata dei
            record dell&rsquo;utente in base a quanto richiesto dalla legge o
            per tutto il tempo necessario agli scopi per cui tali record sono
            stati raccolti o ulteriormente elaborati ai sensi della legge.
          </p>
          <p>Sicurezza dei dati</p>
          <p>
            Quantcast adotta misure amministrative, fisiche e tecniche concepite
            per proteggere le informazioni dell&rsquo;utente dall&rsquo;accesso
            non autorizzato. Tali misure possono includere la crittografia delle
            informazioni fornite dall&rsquo;utente e l&rsquo;impiego di
            tecnologie di sicurezza per la memorizzazione delle informazioni in
            modo da bloccare l&rsquo;accesso esterno alla rete Quantcast.
            L&rsquo;utente dovr&agrave; tenere presente che nessun metodo di
            trasmissione delle informazioni su Internet o di memorizzazione
            delle informazioni &egrave; completamente sicuro. Inoltre, in alcuni
            casi i Partner o altre parti potrebbero fornire a Quantcast
            informazioni in forma non crittografata.
          </p>
          <p>Trasferimenti internazionali</p>
          <p>
            I Dati di log e i Dati personali dell&rsquo;utente potrebbero essere
            trasferiti o gestiti in computer situati al di fuori
            dell&rsquo;area, della provincia o del paese (o altro)
            dell&rsquo;utente, in cui le leggi sulla privacy potrebbero non
            essere altrettanto protettive di quelle in vigore nella
            giurisdizione dell&rsquo;utente. Se l&rsquo;utente si trova in un
            paese diverso dagli Stati Uniti e sceglie di fornire informazioni a
            Quantcast, dovr&agrave; tenere presente che i suoi Dati personali
            verranno trasferiti ed elaborati negli Stati Uniti. Il consenso a
            questa Informativa sulla privacy, seguito dall&rsquo;invio di tali
            informazioni, rappresenta l&rsquo;accettazione da parte
            dell&rsquo;utente di tale trasferimento.
          </p>
          <p>Politiche Quantcast riguardo ai minori</p>
          <p>
            Quantcast non raccoglie intenzionalmente Dati personali di bambini
            di et&agrave; inferiore a 13 anni. Se un genitore o un tutore di un
            bambino viene a conoscenza del fatto che questi ha fornito Dati
            personali senza il proprio consenso, dovr&agrave; contattare
            Quantcast. Qualora Quantcast si renda conto che un bambino di
            et&agrave; inferiore a 13 anni ha fornito Dati personali,
            eliminer&agrave; tali informazioni dai propri file.
          </p>
          <p>Informazioni sulle condizioni di salute</p>
          <p>
            Quantcast non raccoglie o utilizza intenzionalmente alcuna
            informazione riservata correlata alla salute, quali, ad esempio,
            informazioni relative a condizioni o prescrizioni mediche passate o
            presenti. Quantcast fornisce tuttavia supporto ad alcuni
            inserzionisti suoi clienti nel presentare annunci per prodotti
            correlati a salute, medicina o benessere. Quantcast fornisce inoltre
            i seguenti segmenti standard correlati a salute e benessere:
            prodotti cosmetici, pannolini, cura dei capelli e igiene orale.
          </p>
          <p>Altri siti Web e app</p>
          <p>
            Quantcast richiede ai propri Partner mediante contratto di garantire
            che i siti e le app da essi forniti e contenenti Tag pubblichino
            informative sulla privacy adeguate e proteggano altrimenti i diritti
            alla privacy dei visitatori. Tuttavia, il fatto che Quantcast
            consenta il collegamento a un&rsquo;app o a un sito specifico o
            riceva dati da un&rsquo;app o un sito non significa che Quantcast
            approvi tale app o sito, n&eacute; le politiche sulla privacy
            correlate. Tali siti Web adottano condizioni di utilizzo e
            informative sulla privacy proprie e Quantcast invita l&rsquo;utente
            a leggere tali condizioni e informative durante la propria visita.
          </p>
          <p>Controllo dei dati Quantcast</p>
          <p>
            Il Sito e i Servizi di Quantcast.com vengono resi disponibili e
            forniti alle persone che risiedono negli Stati Uniti da Quantcast
            Corporation, all&rsquo;indirizzo 795 Folsom Street, San Francisco,
            CA 94107. Le informazioni o i dati correlati alle persone che
            risiedono negli Stati Uniti forniti ai o raccolti dai Siti o Servizi
            di Quantcast.com vengono controllati da Quantcast Corporation.
          </p>
          <p>
            I Siti e i Servizi di Quantcast.com vengono resi disponibili alle
            persone residenti al di fuori degli Stati Uniti da Quantcast
            International Limited, Beaux Lane House, Lower Mercer Street, 1st
            Floor, Dublin 2, Ireland. Le informazioni o i dati correlati alle
            persone che risiedono al di fuori degli Stati Uniti forniti ai o
            raccolti dai Siti o Servizi di Quantcast.com vengono controllati da
            Quantcast International Limited.
          </p>
          <hr /> <br />
          <p>
            <strong>GOOGLE</strong>
          </p>
          <p>
            Google raccoglie informazioni sui servizi utilizzati
            dall&rsquo;utente e sulla modalit&agrave; di utilizzo, ad esempio
            quando viene guardato un video su YouTube, viene visitato un sito
            web su cui vengono utilizzati i servizi pubblicitari, oppure quando
            l&rsquo;utente visualizza e interagisce con gli annunci adv e
            contenuti.{" "}
          </p>
          <p>
            Le informazioni raccolte mentre l&rsquo;utente &egrave; collegato al
            proprio account Google, oltre alle informazioni sull&rsquo;utente
            fornite dai partner, possono essere associate all&rsquo;account
            stesso. Una volta associate all&rsquo;account Google
            dell&rsquo;utente, tali informazioni vengono considerate come
            informazioni personali.{" "}
          </p>
          <p>
            Google utilizza le informazioni raccolte da tutti i propri servizi
            per poterli fornire, gestire, proteggere e migliorare, per
            svilupparne di nuovi e per proteggere Google e i suoi utenti. Queste
            informazioni vengono utilizzate anche per offrire contenuti
            personalizzati, ad esempio per visualizzare risultati di ricerca e
            annunci pi&ugrave; pertinenti in base agli interessi degli utenti.
          </p>
          <p>
            Queste informazioni comprendono:
            <br /> <br /> &ndash; Informazioni sul <em>dispositivo</em>: ad
            esempio il modello del computer o del dispositivo mobile, versione
            del sistema operativo, identificatori univoci dei dispositivi e
            informazioni sulla rete mobile, compreso il numero di telefono).
            <br /> <br /> &ndash; Informazioni sui <em>log</em>: query di
            ricerca, numero di telefono, numero del chiamante, numeri di
            deviazione, ora e data delle chiamate, durata delle chiamate,
            informazioni sull&rsquo;inoltro di SMS e tipi di chiamate, Indirizzo
            di protocollo Internet, cookie che potrebbero identificare in modo
            univoco il browser o l&rsquo;account Google dell&rsquo;utente.
          </p>
          <p>
            &ndash; Dati sulla <em>posizione</em>: indirizzo IP, GPS e altri
            sensori che potrebbero, ad esempio, fornire a Google informazioni
            sui dispositivi, sui punti di accesso Wi-Fi e sui ripetitori di
            segnale dei cellulari nelle vicinanze.
            <br />{" "}
          </p>
          <p>
            &ndash; Cookie e tecnologie simili: Google e i propri partner
            utilizzano diverse tecnologie per raccogliere e memorizzare
            informazioni quando viene visitato un servizio di Google, che
            potrebbero prevedere l&rsquo;utilizzo di cookie o tecnologie simili
            per identificare il browser o dispositivo dell&rsquo;utente.
            <br /> Le informazioni raccolte mentre l&rsquo;utente &egrave;
            collegato al proprio account Google possono essere associate
            all&rsquo;account stesso. Una volta associate all&rsquo;account
            Google dell&rsquo;utente, tali informazioni vengono considerate come
            informazioni personali.{" "}
          </p>
          <p>Modalit&agrave; di utilizzo dei dati raccolti</p>
          <p>
            Google utilizza i dati raccolti tramite i cookie e altre tecnologie,
            come i tag di pixel, per migliorare l&rsquo;esperienza degli utenti
            (ad esempio attraverso l&rsquo;analisi dell&rsquo;interazione con i
            propri servizi). Quando mostriamo annunci personalizzati per
            l&rsquo;utente non vengono utilizzati identificatori provenienti da
            cookie o da tecnologie simili a categorie sensibili quali razza,
            religione, orientamento sessuale o salute.
            <br /> <br /> I sistemi automatizzati di Google analizzano i
            contenuti dell&rsquo;utente (incluse le email) al fine di mettere a
            sua disposizione funzioni di prodotto rilevanti a livello personale,
            come risultati di ricerca personalizzati, pubblicit&agrave; su
            misura e rilevamento di spam e malware. A seconda delle impostazioni
            dell&rsquo;account utente, la sua attivit&agrave; su altri siti e
            app potrebbe essere associata alle relative informazioni personali
            allo scopo di migliorare la pertinenza degli annunci pubblicati da
            Google.
          </p>
          <p>
            Viene richiesto il consenso esplicito dell&rsquo;utente per
            utilizzare le informazioni per scopi diversi da quelli elencati.
          </p>
          <p>
            Google tratta le informazioni personali sui suoi server in diversi
            Paesi in tutto il mondo, potrebbe quindi trattare informazioni
            personali su un server sito in un Paese diverso da quello in cui si
            trova l&rsquo;utente.
          </p>
          <p>
            Informazioni condivise da Google
            <br /> <br /> Google fornisce dati personali a societ&agrave;,
            organizzazioni e persone che non fanno parte di Google con il
            consenso dell&rsquo;utente per l&rsquo;attivazione della
            condivisione di dati personali sensibili.
          </p>
          <p>
            Google pu&ograve; condividere informazioni non idonee
            all&rsquo;identificazione personale pubblicamente e con i propri
            partner (ad esempio publisher, inserzionisti o siti collegati), come
            ad esempio per mostrare le tendenze relative all&rsquo;utilizzo
            generale dei propri servizi o sulle tendenze di ricerca del momento.
          </p>
          <p>Utilizzo dei cookie nella pubblicit&agrave; da parte di Google</p>
          <p>
            Google utilizza i cookie per diversi scopi, ad esempio per non
            mostrare all&rsquo;utente lo stesso annuncio pi&ugrave; volte e per
            mostrare annunci pi&ugrave; pertinenti, ad esempio sulla base dei
            siti web che ha visitato.
          </p>
          <p>
            Nei log Google vengono conservati i record degli annunci che vengono
            pubblicati. Questi log del server normalmente includono la richiesta
            sul web dell&rsquo;utente, l&rsquo;indirizzo IP, il tipo di browser,
            la lingua del browser, la data e l&rsquo;ora della richiesta e uno o
            pi&ugrave; cookie che possono identificare in modo univoco il
            browser dell&rsquo;utente. I dati dei log vengono resi anonimi
            tramite la rimozione di una parte dell&rsquo;indirizzo IP (dopo 9
            mesi) e delle informazioni sui cookie (dopo 18 mesi).
          </p>
          <p>
            Quando un utente visita una pagina o visualizza un annuncio che
            utilizza uno di questi prodotti, sui servizi Google o su altri siti
            e app, al suo browser potrebbero essere inviati alcuni cookie.
            <br /> <br /> L&rsquo;utente ha la possibilit&agrave; di gestire gli
            annunci Google visualizzati e disattivare la personalizzazione degli
            annunci. Anche se viene disattivata la personalizzazione degli
            annunci per&ograve;, l&rsquo;utente potrebbe comunque trovare
            annunci basati su fattori quali la posizione approssimativa dedotta
            dall&rsquo;indirizzo IP, il tipo di browser e termini di ricerca che
            utilizzati.
          </p>
          <p>Tipi di cookie utilizzati da Google</p>
          <p>
            Google utilizza diversi tipi di cookie per gestire i prodotti a cui
            si riferiscono gli annunci e i siti web di Google, di seguito
            elencati:
          </p>
          <table>
            <tbody>
              <tr className={style.head_tab}>
                <td>
                  <p>
                    <strong>Categoria di utilizzo</strong>
                  </p>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <p>Preferenze</p>
                </td>
                <td>
                  <p>
                    Questi cookie permettono ai siti web Google di memorizzare
                    informazioni che modificano il comportamento o
                    l&rsquo;aspetto dei siti stessi, come la lingua preferita o
                    l&rsquo;area geografica. Memorizzando l&rsquo;area
                    geografica, ad esempio, un sito web potrebbe essere in grado
                    di offrire previsioni meteo locali o notizie sul traffico
                    locale. I cookie possono anche aiutare a modificare le
                    dimensioni del testo, il tipo di carattere e altre parti
                    personalizzabili delle pagine web.
                  </p>
                  <p>
                    La perdita delle informazioni memorizzate in un cookie delle
                    preferenze potrebbe rendere meno funzionale
                    l&rsquo;esperienza sul sito web ma non dovrebbe
                    comprometterne il funzionamento.
                  </p>
                  <p>
                    La maggior parte degli utenti di Google ha un cookie delle
                    preferenze chiamato &ldquo;NID&rdquo; nei browser. Il
                    browser invia questo cookie con le richieste ai siti di
                    Google. Il cookie NID contiene un ID univoco che viene
                    utilizzato da Google per memorizzare le preferenze
                    dell&rsquo;utente e altre informazioni, ad esempio la lingua
                    preferita (ad esempio l&rsquo;italiano), il numero di
                    risultati di ricerca che desideri vengano mostrati per ogni
                    pagina (ad esempio 10 o 20) e la tua preferenza di
                    attivazione del filtro SafeSearch di Google.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Sicurezza</p>
                </td>
                <td>
                  <p>
                    Google utilizza i cookie di sicurezza per autenticare gli
                    utenti, prevenire l&rsquo;uso fraudolento delle credenziali
                    di accesso e proteggere i dati degli utenti da soggetti non
                    autorizzati.
                  </p>
                  <p>
                    Utilizza cookie chiamati &ldquo;SID&rdquo; e
                    &ldquo;HSID&rdquo; contenenti record con firma digitale e
                    crittografati per l&rsquo;ID dell&rsquo;account Google di un
                    utente e per la sua data di accesso pi&ugrave; recente. La
                    combinazione di questi due cookie permette di bloccare molti
                    tipi di attacchi, ad esempio i tentativi di rubare i
                    contenuti dei moduli sulle pagine web.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Processi</p>
                </td>
                <td>
                  <p>
                    I cookie dei processi contribuiscono al funzionamento dei
                    siti web e all&rsquo;offerta dei servizi che i visitatori si
                    aspettano di trovarvi, ad esempio la possibilit&agrave; di
                    navigare tra le pagine o di accedere ad aree protette del
                    sito. Senza questi cookie, il sito non pu&ograve; funzionare
                    correttamente.
                  </p>
                  <p>
                    Ad esempio, utilizza un cookie chiamato &ldquo;lbcs&rdquo;
                    che consente a Google Documenti di aprire molti documenti in
                    un unico browser. Il blocco di questo cookie potrebbe
                    compromettere il corretto funzionamento di Google Documenti.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Pubblicit&agrave;</p>
                </td>
                <td>
                  <p>
                    Alcuni impieghi comuni dei cookie includono la selezione
                    della pubblicit&agrave; in base a ci&ograve; che &egrave;
                    pertinente per un utente, il miglioramento dei rapporti sul
                    rendimento delle campagne e la possibilit&agrave; di evitare
                    la visualizzazione di annunci che l&rsquo;utente ha
                    gi&agrave; visto.
                  </p>
                  <ul>
                    <li>
                      Google utilizza i cookie, come i cookie NID e SID, per
                      contribuire alla personalizzazione degli annunci nelle
                      propriet&agrave; di Google, come la Ricerca Google. Ad
                      esempio, li utilizziamo per memorizzare le ricerche
                      pi&ugrave; recenti degli utenti, le loro interazioni
                      precedenti con gli annunci di un inserzionista o i
                      risultati di ricerca e le tue visite al sito web di un
                      inserzionista. In questo modo possiamo essere mostrati
                      annunci personalizzati su Google.
                      <br />{" "}
                    </li>
                    <li>
                      Uno dei pi&ugrave; importanti cookies pubblicitari
                      &egrave; chiamato IDE ed &egrave; memorizzato nei browsers
                      sotto il dominio doubleclick.net. Un altro chiamato ANID
                      &egrave; memorizzato sotto il dominio google.com. Vengono
                      utilizzati anche altri cookies chiamati DSID, FLC, AID,
                      TAID, ed exchange_uid. Anche altre societ&agrave; di
                      Google, come YouTube, potrebbero utilizzare questi cookies
                      per mostrare pubblicit&agrave; pi&ugrave; pertinenti.
                      <br />{" "}
                    </li>
                    <li>
                      A volte cookies pubblicitari possono essere impostati nel
                      dominio del sito che l&rsquo;utente visitando. Nel caso di
                      pubblicit&agrave; che viene mostrata in tutto il web,
                      cookies chiamati &lsquo;__gads&rsquo; o
                      &lsquo;__gac&rsquo; possono essere impostati nel sito web
                      che l&rsquo;utente sta visitando. Diversamente dai cookies
                      impostati su i domini Google, questi cookies non possono
                      essere letti da Google quando si naviga su un sito diverso
                      da quello in cui sono stati impostati. Questi servono a
                      quantificare le interazioni con le inserzioni
                      pubblicitarie sul quel determinato dominio ed evitare che
                      la stesso annuncio sia mostrato alla stessa persona troppe
                      volte.
                      <br />{" "}
                    </li>
                    <li>
                      Google usa anche cookies di conversione con i quali si
                      d&agrave; la possibilit&agrave; all&rsquo;inserzionista di
                      determinare quante persone che hanno cliccato sul suo
                      annuncio sono poi diventate acquirenti dei relativi
                      prodotti. Questi cookies permettono a Google e
                      all&rsquo;inserzionista di vedere che l&rsquo;utente ha
                      cliccato sull&rsquo;annuncio e che successivamente ha
                      visitato il sito. I cookies di conversione non sono usati
                      per annunci personalizzati, focalizzando e insistendo per
                      una volta sola. Questo tipo di attivit&agrave; &egrave;
                      realizzata grazie a un cookie chiamato
                      &lsquo;Conversion&rsquo; il quale &egrave; impostato,
                      generalmente, sotto i domini googleadservices.com o
                      google.com (puoi trovare una lista dei domini che usiamo
                      per cookies pubblicitari alla fine di questa pagina).
                      Alcuni degli altri cookies possono essere usati anche per
                      quantificare eventi di conversione. Ad esempio, i cookies
                      di DoubleClick e Google Analytics.
                      <br />{" "}
                    </li>
                    <li>
                      Utilizza anche cookies chiamati &lsquo;AID&rsquo;,
                      &lsquo;DSID&rsquo; e &lsquo;TAID&rsquo; che sono usati per
                      predisporre il link tra le attivit&agrave;
                      dell&rsquo;utente realizzate su diversi dispositivi se ha
                      prima effettuato l&rsquo;accesso con il proprio Account
                      Google su un altro dispositivo. Questo per sincronizzare
                      gli annunci che vede su diversi dispositivi e quantificare
                      eventuali eventi di conversione. Questo tipo di cookies
                      possono essere impostati sotto i nomi di dominio
                      google.com/ads, google.com/ads/measurement, oppure
                      googleadsservices.com.{" "}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Stato della sessione</p>
                </td>
                <td>
                  <p>
                    I siti web spesso raccolgono informazioni sul modo in cui
                    gli utenti interagiscono con essi. Tali informazioni
                    potrebbero riguardare le pagine visitate pi&ugrave; spesso
                    dagli utenti e gli eventuali messaggi di errore che ricevono
                    da determinate pagine. Vengono utilizzati questi
                    &ldquo;cookie relativi allo stato della sessione&rdquo; per
                    migliorare i servizi e l&rsquo;esperienza di navigazione
                    degli utenti. Il blocco o l&rsquo;eliminazione di questi
                    cookie non render&agrave; inutilizzabile il sito web.
                  </p>
                  <p>
                    Questi cookie potrebbero essere utilizzati anche per
                    misurare l&rsquo;efficacia della pubblicit&agrave; PPC (Pay
                    Per Click) e della pubblicit&agrave; affiliata.
                  </p>
                  <p>
                    Ad esempio, viene utilizzato un cookie chiamato
                    &ldquo;recently_watched_video_id_list&rdquo; per consentire
                    a YouTube di registrare i video guardati pi&ugrave; di
                    recente da un determinato browser.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Analytics</p>
                </td>
                <td>
                  <p>
                    Google Analytics &egrave; lo strumento di analisi di Google
                    che aiuta i proprietari di siti web e app a capire come i
                    visitatori interagiscono con i contenuti di loro
                    propriet&agrave;. Questo servizio potrebbe utilizzare un
                    insieme di cookie per raccogliere informazioni e generare
                    statistiche sull&rsquo;utilizzo dei siti web senza fornire
                    informazioni personali sui singoli visitatori a Google. Il
                    cookie principale utilizzato da Google Analytics &egrave;
                    &ldquo;__ga&rdquo;.
                  </p>
                  <p>
                    Google Analytics, oltre a fornire le statistiche di utilizzo
                    del sito web, pu&ograve; essere anche usato insieme ad
                    alcuni dei cookies pubblicitari sopra descritti al fine di
                    mostrare inserzioni pi&ugrave; pertinenti su altri prodotti
                    di di Google (come Google Search) ma in generale su tutto il
                    web, e di misurare le interazioni con gli annunci che
                    vengono mostrati.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <br /> Per ulteriori informazioni inerenti la privacy policy di
            Google e le modalit&agrave; con cui vengono trattati i dati si
            possono consultare i seguenti link:
          </p>
          <p>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/privacy/
            </a>
            <br />{" "}
            <a
              href="https://www.google.com/policies/technologies/types/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/technologies/types/
            </a>
            <br />{" "}
            <a
              href="https://www.google.com/policies/technologies/ads/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/technologies/ads/
            </a>
            <br />{" "}
            <a
              href="https://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/privacy/partners/
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>FACEBOOK</strong>
          </p>
          <p>
            Facebook usa i cookie per mostrare le inserzioni di aziende e altre
            organizzazioni alle persone che potrebbero essere interessate ai
            loro prodotti, servizi o alle cause promosse.
            <br /> Ad esempio: i cookie consentono di mostrare le inserzioni
            alle persone che in precedenza hanno visitato il sito Web di
            un&rsquo;azienda, acquistato i suoi prodotti o usato le sue app.
          </p>
          <p>
            I cookie permettono inoltre di limitare il numero di visualizzazioni
            di un&rsquo;inserzione; in questo modo, lo stesso utenti non
            visualizzer&agrave; ripetutamente la stessa inserzione.
          </p>
          <p>
            <br /> I cookie vengono utilizzati anche per misurare le prestazioni
            delle campagne pubblicitarie, ad esempio per contare il numero di
            visualizzazioni di un&rsquo;inserzione e calcolare il costo di tali
            inserzioni, per misurare la frequenza di alcune azioni, ad esempio i
            clic o le visualizzazioni delle inserzioni.
          </p>
          <p>
            <br /> I cookie consentono di mostrare e misurare le inserzioni in
            browser e dispositivi diversi usati dalla stessa persona.
          </p>
          <p>
            <br /> I cookie permettono inoltre di fornire dati statistici sulle
            persone che usano i Servizi di Facebook, sulle persone che
            interagiscono con le inserzioni, i siti Web e le app degli
            inserzionisti e sulle aziende che usano i Servizi di Facebook. Ad
            esempio per consentire alle aziende di comprendere quali tipi di
            persone hanno messo &ldquo;Mi piace&rdquo; alla loro Pagina Facebook
            o usano le loro app in modo che possano fornire contenuti pi&ugrave;
            pertinenti e sviluppare funzioni interessanti per i loro clienti.
          </p>
          <p>Per maggiori informazioni consultare i seguenti link:</p>
          <p>
            <a
              href="https://www.facebook.com/policies/cookies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/policies/cookies/
            </a>
          </p>
          <p>
            <a
              href="https://www.facebook.com/legal/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.facebook.com/legal/terms
            </a>
          </p>
          <hr /> <br />
          <p>
            <strong>YOUTUBE</strong>
          </p>
          <p>
            Youtube &egrave; la piattaforma di Google per l&rsquo;erogazione di
            formati video.
            <br /> La pianificazione delle campagne avviene attraverso lo stesso
            pannello Adwords utilizzato per la generazione e la gestione di
            campagne Search, che consente di avere il controllo su dove
            pubblicare gli annunci video, quando pubblicarli e a chi mostrarli.
          </p>
          <p>
            I contenuti degli annunci video devono essere ospitati su YouTube,
            ma gli annunci video possono essere visualizzati sia su YouTube sia
            su app e siti partner video della Rete Display, su tablet e
            dispositivi mobili (in base al formato e alle impostazioni).
          </p>
          <p>
            L&rsquo;annuncio video viene riprodotto prima, durante o dopo altri
            video. Dopo 5 secondi, chi guarda il video ha la possibilit&agrave;
            di ignorare l&rsquo;annuncio.
          </p>
          <p>
            Gli annunci video vengono pubblicati su YouTube e sul Web attraverso
            la Rete Display di Google.
            <br /> Grazie all&rsquo;ampia gamma di metodi di targeting che
            Google mette a disposizione:
          </p>
          <ul>
            <li>
              Gruppi demografici: et&agrave;, sesso,stato parentale, reddito del
              nucleo familiare
              <br />{" "}
            </li>
            <li>
              Interessi: selezionando, tra le categorie di pubblico disponibili,
              quelle che consentono di raggiungere utenti interessati a
              determinati argomenti, anche quando visitano pagine che trattano
              altri temi.
              <br />{" "}
            </li>
            <li>
              Eventi importanti: raggiungendo i potenziali clienti su YouTube e
              Gmail durante gli appuntamenti importanti della vita, come la
              laurea o il matrimonio.
              <br />{" "}
            </li>
            <li>
              Segmenti di pubblico in-market: individuando i clienti che
              eseguono ricerche di prodotti e valutano seriamente la
              possibilit&agrave; di acquistare prodotti o servizi simili a
              quelli che si tanno promuovendo.
              <br />{" "}
            </li>
            <li>
              Remarketing video: raggiungendo gli utenti in base alle loro
              precedenti interazioni con i videoo il canale YouTube.
              <br />{" "}
            </li>
            <li>
              Argomenti: il targeting per argomento consente di raggiungere
              un&rsquo;ampia gamma di video, canali e siti web correlati agli
              argomenti selezionati.
              <br />{" "}
            </li>
            <li>
              Parole chiave: in base al formato, puoi mostrare gli annunci video
              in corrispondenza di parole o frasi (parole chiave) correlate a un
              video YouTube, a un canale YouTube oppure a un tipo di sito web a
              il tuo segmento di pubblico &egrave; interessato.
            </li>
          </ul>
          <p>
            Per le modalit&agrave; di gestione e di trattativa dei dati e ai
            cookie utilizzati, si rimanda al paragrafo di Google.
          </p>
          <hr /> <br />
          <p>
            <strong>AFFILIATION NETWORK</strong>
            <strong>
              <br />{" "}
            </strong>
            <br /> Tra i diversi canali utilizzati da Alleanza luce &amp; gas
            nella pianificazione delle campagne pubblicitarie, figurano i
            Network di Affiliazione.
            <br /> Questi network permettono all&rsquo;inserzionista di
            diffondere le proprie creativit&agrave; pubblicitarie tramite un
            insieme di editori di siti web (detti affiliati) che mettono a
            disposizione i propri spazi pubblicitari.
            <br /> Gli affiliati vengono remunerati dal network tramite una
            piattaforma tecnologica che &egrave; in grado di riconoscere ad ogni
            affiliato una remunerazione proporzionale ai risultati portati
            all&rsquo;inserzionista.
          </p>
          <p>
            I network di affiliazione installano dei cookie sui dispositivi
            degli utenti che visitano i siti web e utilizzano i servizi online
            dei clienti allo scopo di registrare le transazioni commerciali
            (leads e vendite, tra cui la sottoscrizione a una newsletter o
            l&rsquo;inoltro di un ordine su un negozio online). I cookie di
            tracciamento utilizzati hanno il solo scopo di associare un annuncio
            pubblicitario(banner/link/newsletter) a una specifica transazione
            commerciale e di effettuare i relativi servizi di fatturazione.
            Durante il tracciamento non vengono raccolti o trattati dati di
            identificazione personale dell&rsquo;utente.{" "}
          </p>
          <p>
            La memorizzazione di dati personali non avviene in alcun modo. Il
            tracciamento tramite cookie si basa su un ID anonimo, generato come
            ID dell&rsquo;evento, che non &egrave; correlato in alcun modo a
            dati personali.
          </p>
          <p>
            Ad Alleanza luce &amp; gas viene abilitato da parte dei Network
            l&rsquo;accesso alla propria piattaforma per il monitoraggio delle
            attivit&agrave;. Le credenziali sono in possesso di un numero
            ristretto di dipendenti Alleanza luce &amp; gas deputati a svolgere
            la mansione specifica.
            <br />{" "}
          </p>
          <p> Le informazioni raccolte dalle piattaforme sono:</p>
          <ul>
            <li>data e ora della visita</li>
            <li>indirizzo IP</li>
            <li>identificativo unico del dispositivo</li>
            <li>tipologia di browser</li>
            <li>
              le informazioni sul sistema operativo del computer
              dell&rsquo;utente
            </li>
            <li>quali inserzioni vengono viste dall&rsquo;utente</li>
            <li>in quali pagine l&rsquo;utente ha visto le inserzioni</li>
            <li>
              quando l&rsquo;utente ha interagito con le inserzioni (click)
            </li>
            <li>
              codice identificativo dell&rsquo;ordine o del rilascio
              dell&rsquo;anagrafica (ORDER ID o LEAD ID)
            </li>
            <li>
              data e ora dell&rsquo;ordine/lead
              <br />{" "}
            </li>
          </ul>
          <p>
            La piattaforma inoltre condivide queste informazioni con i siti
            internet affiliati alla campagna. Tale attivit&agrave; &egrave;
            funzionale a ricondurre l&rsquo;acquisto/lead effettuato
            all&rsquo;interazione con l&rsquo;inserzione che l&rsquo;ha
            generato, e di conseguenza alla remunerazione del publisher che ha
            pubblicato l&rsquo;inserzione stessa. Viene tenuto traccia di:
          </p>
          <ul>
            <li>il programma partner dell&rsquo;Advertiser</li>
            <li>il Publisher</li>
            <li>
              l&rsquo;orario specifico dell&rsquo;azione da parte
              dell&rsquo;utente (clic o view).
            </li>
          </ul>
          <p>
            Tale tracciamento non prevede in nessun modo la registrazione di
            dati personali ma e&rsquo; funzionale solamente alla fatturazione da
            parte dei network.
          </p>
          <p>
            I cookie possono avere una durata limitata ad una singola sessione
            di navigazione sul browser (c.d. cookie di sessione), ed in tal caso
            si disattivano automaticamente al momento della chiusura del browser
            da parte dell&rsquo;utente; oppure possono avere una scadenza
            predeterminata ed, in tal caso, rimarranno memorizzati ed attivi
            nell&rsquo;hard disk dell&rsquo;utente fino a tale data di scadenza,
            continuando a raccogliere informazioni nel corso di differenti
            sessioni di navigazione sul browser (c.d. cookie permanenti).
          </p>
          <p>
            I cookie sono utilizzati per diverse funzioni. Alcuni sono necessari
            per consentirti di navigare sul Sito e usufruire delle sue
            funzionalit&agrave; (c.d. cookie tecnici). Altri sono utilizzati per
            ottenere informazioni statistiche, in forma aggregata o non, sul
            numero degli utenti che accedono al Sito e su come il Sito viene
            utilizzato (c.d. cookie di monitoraggio o analytics). Altri, infine,
            sono utilizzati per tracciare un profilo di consumatore e fargli
            visualizzare annunci pubblicitari sul Sito che possano essere per
            lui interessanti, in quanto coerenti con i suoi gusti e le sue
            abitudini di consumo (c.d. cookie di profilazione).
          </p>
          <hr /> <br />
          <h4>
            <strong>RETARGETING</strong>
          </h4>
          <br/>
          <p>
            Attraverso specifiche <strong>piattaforme di retargeting</strong>{" "}
            Alleanza luce &amp; gas diffonde inserzioni contenenti prodotti che
            potrebbero interessare all&rsquo;utente, in base al recente
            comportamento o alla ricerca dell&rsquo;utente.
          </p>
          <p>
            I dati raccolti dalle piattaforme non rivelano
            l&rsquo;identit&agrave; degli utenti e le piattaforme si attengono
            in maniera stringente alle regole di privacy online e agli standard
            di sicurezza.
          </p>
          <p>
            I dati che vengono raccolti e immagazzinati sono in forma anonima,
            ovvero si tratta di dati che non rivelano l&rsquo;identit&agrave;
            degli utenti, in maniera aggregata, e che possono solo essere usati
            per funzioni analitiche circa il loro comportamento online. Questo
            permette di migliorare l&rsquo;esperienza di navigazione online e di
            diffondere contenuti pubblicitari il pi&ugrave; possibile
            d&rsquo;interesse e di rilievo per gli utenti online.
          </p>
          <hr /> <br />
          <p>
            <strong>ROCKETFUEL</strong>
          </p>
          <p>Le informazioni raccolte dalla piattaforma sono:</p>
          <ul>
            <li>indirizzo IP</li>
            <li>identificativo unico del dispositivo</li>
            <li>tipologia di browser</li>
            <li>lingua di utilizzo del browser</li>
            <li>
              il server sul quale &egrave; connesso il computer
              dell&rsquo;utente
            </li>
            <li>
              le informazioni sul sistema operativo del computer
              dell&rsquo;utente
            </li>
            <li>quali inserzioni vengono viste dall&rsquo;utente</li>
            <li>in quali pagine l&rsquo;utente ha visto le inserzioni</li>
            <li>
              quando l&rsquo;utente ha interagito con le inserzioni (click)
            </li>
          </ul>
          <p>
            Per identificare il gradimento delle inserzioni inoltre la
            piattaforma pu&ograve; somministrare dei questionari agli utenti in
            cui viene chiesto quale inserzione, prodotto o brand l&rsquo;utente
            gradisce o non gradisce.
          </p>
          <p>
            Inoltre per avere una conoscenza dell&rsquo;interazione avvenuta tra
            l&rsquo;utente e il sito del cliente vengono inseriti dei pixel di
            tracciamento all&rsquo;interno dei software di tracciamento (vedi
            relativo capitolo), che permettono di sapere per esempio se un
            prodotto &egrave; stato aggiunto ad un carrello di acquisto in
            seguito all&rsquo;interazione dell&rsquo;utente con
            un&rsquo;inserzione pubblicitaria.
          </p>
          <p>
            Tutte queste informazioni sono identificate in maniera anonima nel
            database della piattaforma.
          </p>
          <p>
            La piattaforma inoltre pu&ograve; condividere queste informazioni
            raccolte in maniera anonima con aziende partner, che trattano allo
            stesso modo le informazioni (quindi sempre in maniera anonima).{" "}
          </p>
          <p>
            I dati raccolti vengono cancellati dal database di RocketFuel dopo
            90 giorni di inattivit&agrave;.
          </p>
          <p>I dati raccolti non appartengono mai a minori di 13 anni.</p>
          <p>
            Per maggiori informazioni consultare il seguente link:
            http://rocketfuel.com/it/privacy/
          </p>
          <hr /> <br />
          <p>
            <strong>ADROLL</strong>
          </p>
          <p>
            AdRoll offre servizi di advertising mirati ai clienti inserzionisti
            (&ldquo;Inserzionisti&rdquo;). Quando l&rsquo;utente visita un sito
            Web gestito da un Inserzionista o un sito di terze parti su cui
            AdRoll pu&ograve; visualizzare un annuncio, &egrave; possibile che
            vengano raccolti alcuni o tutti i dati descritti nella presente
            Informativa sulla privacy. La piattaforma utilizza tali dati e altri
            dati descritti di seguito per consentire agli Inserzionisti di
            visualizzare gli annunci pi&ugrave; rilevanti per gli utenti.
          </p>
          <p>
            AdRoll raccoglie le seguenti categorie di informazioni e le utilizza
            per gli scopi indicati di seguito:
          </p>
          <ul>
            <li>
              Attivit&agrave; sul sito Web dell&rsquo;Inserzionista: dati
              sull&rsquo;attivit&agrave; di navigazione dei visitatori del sito
              Web dell&rsquo;Inserzionista. Ad esempio, le pagine visitate e
              quando e quali elementi il visitatore ha inserito nel carrello
              online.
            </li>
            <li>
              Informazioni su dispositivo e browser: informazioni tecniche sul
              dispositivo o sul browser utilizzato per accedere al sito Web
              dell&rsquo;Inserzionista. Ad esempio, l&rsquo;indirizzo IP del
              dispositivo e (nel caso di dispositivi mobili) il tipo di
              dispositivo e l&rsquo;ID univoco del dispositivo mobile, come
              Apple IDFA o ID advertising di Android.
            </li>
            <li>
              Dati dell&rsquo;annuncio: dati sugli annunci online distribuiti (o
              che si &egrave; tentato di distribuire). Sono incluse informazioni
              quali il numero di volte che un annuncio &egrave; stato mostrato
              al visitatore, la pagina in cui appare l&rsquo;annuncio e se il
              visitatore ha fatto clic sull&rsquo;annuncio o vi ha interagito in
              altro modo.
            </li>
            <li>
              Dati di Partner di advertising: dati ricevuti da altre
              societ&agrave; di advertising digitale con cui AdRoll collabora
              (&ldquo;Partner di advertising&rdquo;) per consentirei di
              distribuire gli annunci e riconoscere i visitatori su pi&ugrave;
              browser e dispositivi. Possono includere identificatori
              dell&rsquo;inserzionista sotto forma di pseudonimo che alcuni
              Inserzionisti o altre piattaforme di annunci di terze parti
              scelgono di condividere con AdRoll, come l&rsquo; &ldquo;ID
              cliente&rdquo; con un Inserzionista o un identificatore associato
              a una versione codificata (non leggibile) dell&rsquo;indirizzo
              e-mail. AdRoll pu&ograve; collaborare con questi Partner di
              advertising per sincronizzare i loro identificatori univoci e
              anonimi ai nostri e migliorare i punti dati su uno specifico
              browser o dispositivo univoco.
            </li>
            <li>
              Indirizzi e-mail e postale da Inserzionisti: alcuni Inserzionisti
              scelgono di condividere con AdRoll indirizzi e-mail e indirizzi
              postali effettivi dei clienti, in modo che (con l&rsquo;aiuto dei
              Partner di advertising) AdRoll sia in grado di aiutarli a
              distribuire annunci mirati ai clienti. Inoltre, AdRoll pu&ograve;
              assistere un Inserzionista nell&rsquo;invio di messaggi e-mail ai
              clienti (per esempio, se l&rsquo;inserzionista sta utilizzando il
              servizio SendRoll). AdRoll utilizza gli indirizzi e-mail e postali
              forniti dall&rsquo;Inserzionista per aiutare tale Inserzionista.
            </li>
            <li>
              Hash di indirizzi e-mail: se un inserzionista &egrave;
              d&rsquo;accordo, AdRoll pu&ograve; raccogliere le versioni hash
              dei messaggi di posta elettronica che vengono immessi sul suo
              sito. Gli hash sono sfruttati come un identificatore supplementare
              che aiuta a meglio indirizzare gli annunci per gli inserzionisti,
              su molteplici dispositivi o browser.
            </li>
          </ul>
          <p>
            AdRoll utilizza questi dati anche per aiutare gli Inserzionisti a
            identificare e distribuire gli annunci pi&ugrave; rilevanti ai
            clienti. Inoltre, utilizza questi dati per gestire, migliorare e
            sviluppare i servizi forniti, incluso il miglioramento dei punti
            dati che AdRoll o i Partner di advertising utilizzano per un
            determinato utente, browser o dispositivo, o la distribuzione,
            l&rsquo;ottimizzazione, la limitazione o la sincronizzazione degli
            annunci di advertising.
          </p>
          <p>AdRoll pu&ograve; divulgare le informazioni degli utenti:</p>
          <ul>
            <li>
              A un inserzionista di cui l&rsquo;utente ha visitato il sito:
              AdRoll pu&ograve; condividere informazioni in merito
              all&rsquo;interazione con il suo sito o con i suoi annunci.
            </li>
            <li>
              Ai fornitori di servizi: AdRoll stipula contratti con alcune
              societ&agrave; per svolgere determinate attivit&agrave; aziendali.
              Viene richiesto che i fornitori di servizi utilizzino le
              informazioni degli utenti solo per svolgere i servizi a loro
              assegnati per conto di AdRoll.
            </li>
            <li>
              Ai fornitori di servizi degli Inserzionisti: gli Inserzionisti
              possono stipulare contratti con alcune societ&agrave; che
              gestiscono i dati (come gli elenchi di clienti
              dell&rsquo;Inserzionista) per loro conto.
            </li>
            <li>Alle societ&agrave; affiliate e consociate</li>
            <li>
              In relazione a procedimenti legali: quando richiesto dalla legge,
              ad esempio per rispettare un ordine di comparizione presso un
              tribunale o se la divulgazione &egrave; necessaria per esercitare,
              stabilire o difendere i diritti legali di AdRoll, deigli
              Inserzionisti o di qualsiasi altra terza parte.
            </li>
            <li>
              In relazione alla vendita di AdRoll: se una terza parte acquisisce
              AdRoll, in parte o integralmente, o i beni dell&rsquo;azienda,
              AdRoll pu&ograve; divulgare le informazioni degli utenti con la
              vendita.
            </li>
          </ul>
          <p>
            Vengono condivisi anche hash di indirizzi email (o altri
            identificatori associati), i dati tecnici raccolti sulle abitudini
            di navigazione degli utenti e sui dispositivi (come i dati correlati
            ai cookie AdRoll, pixel di monitoraggio e tecnologie simili) con
            altre societ&agrave; pubblicitarie che operano nell&rsquo;ambito
            della pubblicit&agrave; digitale. Questi dati consentono sia a loro
            che ad AdRoll di distribuire annunci pi&ugrave; mirati ai clienti.
          </p>
          <p>
            Infine, AdRoll pu&ograve; divulgare informazioni aggregate e anonime
            a terze parti.
          </p>
          <p>
            La piattaforma AdRoll utilizza cookie, pixel di monitoraggio e
            tecnologie correlate per fornire i servizi agli Inserzionisti. I
            cookie sono file di dati di piccole dimensioni che vengono
            distribuiti dalla piattaforma e memorizzati sul dispositivo degli
            utenti. Consentono di identificare il dispositivo quando il
            visitatore passa tra diversi siti Web e applicazioni, in modo che
            AdRolll possa distribuire annunci mirati.
          </p>
          <p>
            In modo specifico, i cookie di AdRoll distribuiti tramite la
            piattaforma AdRoll per questo scopo si chiamano
            &ldquo;__adroll&rdquo;. E&rsquo; possibile anche rilasciare un
            ulteriore cookie di rifiuto di AdRoll se il visitatore sceglie di
            rifiutare i cookie come descritto qui
            https://www.adroll.com/it-IT/about/privacy. Inoltre, vengono
            rilasciare cookie dei Partner Adroll di advertising per gli scopi
            descritti sopra. I cookie dei Partner di advertising rilasciati
            variano in base all&rsquo;implementazione dell&rsquo;Inserzionista.
          </p>
          <p>
            Adroll conserva i dati di identificazione che raccoglie direttamente
            per scopi di targeting per un periodo di 13 mesi, trascorso il quale
            de-identifica i dati rimuovendo gli identificatori univoci e
            troncando gli indirizzi IP associati.
          </p>
          <p>
            I dati di identificazione personale raccolti per altri scopi non
            vengono conservati pi&ugrave; a lungo di quanto necessario per i
            fini aziendali o per soddisfare i requisiti di legge.
          </p>
          <p>
            AdRoll adotta misure di sicurezza tecniche, amministrative e
            organizzative per proteggere i dati raccolti da distribuzione e
            perdita accidentale o illegale, alterazione, divulgazione o accesso
            non autorizzato, in particolare nei casi in cui l&rsquo;elaborazione
            implichi la trasmissione di dati su una rete e contro altre forme
            illegali di elaborazione.
          </p>
          <p>
            AdRoll pu&ograve; trasferire le informazioni che raccoglie a paesi
            diversi da quello in cui sono state originariamente raccolte per
            finalit&agrave; legate alla memorizzazione ed elaborazione dei dati
            e all&rsquo;offerta dei nostri servizi. In tali paesi potrebbero non
            essere in vigore le stesse leggi sulla protezione dei dati del paese
            in cui sono state inizialmente fornite le informazioni. Tuttavia,
            durante il trasferimento delle informazioni ad altri paesi, AdRoll
            le protegge come descritto nell&rsquo;Informativa sulla privacy al
            link https://www.adroll.com/it-IT/about/privacy e, dove necessario,
            agisce per assicurare che i trasferimenti internazionali siano
            conformi alle leggi vigenti. Per esempio, nel trasferire le
            informazioni dall&rsquo;Area Economica Europea a un paese esterno a
            essa, come gli Stati Uniti, AdRoll cercher&agrave; di adottare
            misure aggiuntive, quali la conclusione di accordi sul trasferimento
            dei dati che siano conformi sia alle norme UE sia a quelle del paese
            destinatario delle informazioni.
          </p>
          <hr /> <br />
          <p>
            <strong>QUANTCAST</strong>
          </p>
          <p>
            Quantcast offre due prodotti principali: (1) Quantcast Measure e (2)
            Quantcast Advertise.{" "}
          </p>
          <p>
            Il prodotto Quantcast Measure &egrave; gratuito e aiuta i
            proprietari di siti Web a comprendere le caratteristiche e i dati
            demografici delle persone che visitano i loro siti.{" "}
          </p>
          <p>
            Quantcast Advertise consente alle aziende di erogare
            pubblicit&agrave; online pertinente per singoli consumatori.
            Quantcast &egrave; impegnata a rendere la pubblicit&agrave; online
            pi&ugrave; efficace e piacevole per ognuno. A questo scopo,
            l&rsquo;azienda raccoglie e analizza informazioni in forma anonima
            sugli interessi dei singoli consumatori.
          </p>
          <hr /> <br />
          <p>
            <strong>Cookie</strong>
          </p>
          <p>
            Se l&rsquo;utente visita un sito con cookie Quantcast con il browser
            impostato per accettare i cookie, Quantcast presuppone che
            l&rsquo;utente abbia acconsentito all&rsquo;impostazione e alla
            lettura dei cookie da parte di Quantcast dal browser. L&rsquo;utente
            non &egrave; obbligato a utilizzare i cookie, ma se non li accetta,
            potrebbe non essere in grado di accedere a tutte le parti di
            Quantcast.com o a tutte le funzionalit&agrave; dei Servizi. Inoltre,
            alcuni siti Web ospitano versioni precedenti del codice che in
            passato utilizzavano cookie Flash. Questi cookie non vengono
            pi&ugrave; utilizzati per alcuno scopo. Se l&rsquo;utente desidera
            eliminare i cookie Flash, dovr&agrave; accedere allo strumento di
            gestione delle impostazioni di Flash Player (disponibile nel sito
            Web di Adobe) o fare clic qui
            http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
          </p>
          <hr /> <br />
          <p>
            <strong>Tag</strong>
          </p>
          <p>
            I Tag Quantcast sono piccolissimi elementi grafici inseriti dai
            Partner nelle proprie pagine Web e righe di codice contenute nelle
            app che creano. Nelle app questo codice viene chiamato SDK. Quando
            un utente visualizza un sito o utilizza un&rsquo;app con uno dei Tag
            Quantcast, i server Quantcast ricevono una notifica e
            l&rsquo;azienda pu&ograve; raccogliere le informazioni dal browser o
            dal dispositivo mobile.
          </p>
          <hr /> <br />
          <p>
            <strong>Dati di log</strong>
          </p>
          <p>
            I Dati di log includono (1) informazioni inviate a Quantcast dai
            browser che trovano Tag Quantcast, quali tipo di browser, ora del
            browser, data e ora di accesso, risoluzione dello schermo, indirizzo
            IP, sito URL di riferimento, sito URL corrente e stringhe di
            ricerca; e (2) informazioni ricevute dall&rsquo;SDK Quantcast
            incorporato nelle app per dispositivi mobile, che possono includere
            quelle citate sopra nonch&eacute; l&rsquo;identificatore del
            dispositivo, informazioni sulla localit&agrave;, informazioni su
            dati e utilizzo dell&rsquo;applicazione e identificatori univoci
            dell&rsquo;applicazione e dell&rsquo;installazione.
          </p>
          <p>Dati personali (PII, Personally Identifiable Information)</p>
          <p>
            I Dati personali (PII, Personally Identifiable Information) sono
            informazioni sull&rsquo;utente che possono essere utilizzate per
            contattarlo o identificarlo come individuo. I Dati personali possono
            includere: nome e cognome, nome utente, numero di telefono,
            indirizzo e-mail e indirizzo fisico.
          </p>
          <p>
            Dati non personali (Non-PII, Non-Personally Identifiable
            Information)
          </p>
          <p>
            Per Dati non personali si intendono informazioni sulle persone che,
            in alcuni casi, possono consentire l&rsquo;identificazione di un
            dispositivo o un browser, ma non di un individuo. Ad esempio, i
            seguenti vengono considerati Dati non personali: codice postale,
            localit&agrave; approssimativa (ad esempio area, citt&agrave;,
            codice postale), tipo di browser, ID cookie, identificatori dei
            dispositivi, indirizzo IP, siti Web visitati dall&rsquo;utente e
            annunci pubblicitari visualizzati o selezionati. Anche i dati
            aggregati, privati dell&rsquo;identit&agrave; dell&rsquo;utente e/o
            resi anonimi vengono considerati Dati non personali. Le
            giurisdizioni non accettano tutte la stessa definizione di Dati
            personali e Dati non personali. Alcune giurisdizioni considerano gli
            indirizzi IP Dati personali. Quando Quantcast memorizza un indirizzo
            IP, lo fa in forma troncata o crittografata.
          </p>
          <hr /> <br />
          <p>
            <strong>Informazioni raccolte dal Sito Quantcast</strong>
          </p>
          <p>
            Quando un Visitatore o un Utente registrato visita Quantcast.com, i
            server dell&rsquo;azienda registrano e raccolgono i Dati di log
            inviati automaticamente dal suo browser. I Dati di log vengono
            utilizzati da Quantcast per monitorare, analizzare, amministrare e
            migliorare il Sito e i Servizi Quantcast. Quantcast richiede ai
            Partner Quantcast di fornire alcuni Dati personali come parte
            dell&rsquo;accesso per utilizzare i prodotti Measure e Advertise,
            quali nome e cognome, e-mail di contatto, indirizzo fisico e numero
            di telefono. Quantcast utilizza questi Dati personali principalmente
            per fornire i Servizi, amministrare gli account e gestire eventuali
            domande e problemi posti dall&rsquo;utente.
          </p>
          <p>
            Se l&rsquo;utente contatta Quantcast tramite e-mail, Quantcast
            potrebbe mantenere un record delle informazioni di contatto e della
            corrispondenza. Se l&rsquo;utente fornisce informazioni di contatto,
            Quantcast potrebbe utilizzarle per contattarlo con newsletter o
            materiali di marketing o promozionali. Se in qualsiasi momento
            l&rsquo;utente decide di non voler pi&ugrave; ricevere queste
            comunicazioni, dovr&agrave; seguire le istruzioni per
            l&rsquo;annullamento della sottoscrizione disponibili in tutte le
            comunicazioni.
          </p>
          <hr /> <br />
          <p>
            <strong>Informazioni raccolte tramite i Servizi</strong>
          </p>
          <p>
            Quando l&rsquo;utente visita un&rsquo;app o un sito gestito da un
            Partner Quantcast, il Partner consente a Quantcast di raccogliere
            Dati di log (definiti sopra); Quantcast riceve i Dati non personali
            direttamente dai Partner. Le informazioni vengono associate a un
            identificatore univoco anonimo (l&rsquo;ID cookie) non al nome,
            all&rsquo;indirizzo e-mail, all&rsquo;indirizzo o al numero di
            telefono. Queste informazioni vengono utilizzate da Quantcast per
            migliorare l&rsquo;accuratezza del prodotto Measure (descritto
            sopra) e per determinare gli annunci pi&ugrave; interessanti per
            l&rsquo;utente.{" "}
          </p>
          <hr /> <br />
          <p>
            <strong>Condivisione e divulgazione delle informazioni</strong>
          </p>
          <p>Dati personali</p>
          <p>
            Quantcast non condivide con n&eacute; divulga a terze parti i Dati
            personali degli utenti, ad eccezione di quanto richiesto
            dall&rsquo;utente o indicato di seguito nella sezione sulle
            informazioni condivise con i provider di servizi di Quantcast.
          </p>
          <p>Dati non personali</p>
          <p>
            Quantcast potrebbe condividere Dati non personali con terze parti,
            inclusi determinati Dati di log, come parte della distribuzione e
            del miglioramento dei prodotti Measure e Advertise. Ad esempio,
            Quantcast potrebbe divulgare tali dati a societ&agrave; coinvolte
            nell&rsquo;erogazione di annunci o nella visibilit&agrave; degli
            annunci. Analogamente, Quantcast potrebbe divulgare tali dati per
            rendere disponibili o semplificare la misurazione
            dell&rsquo;audience del sito, l&rsquo;analisi del traffico o
            l&rsquo;analisi demografica e per consentire ai siti Web di fornire
            ai propri inserzionisti segmenti di audience appropriati per i
            prodotti o i servizi al loro interno.
          </p>
          <p>Informazioni condivise con i provider di servizi di Quantcast</p>
          <p>
            Quantcast pu&ograve; avvalersi di terze parti e delle proprie
            societ&agrave; affiliate per l&rsquo;amministrazione e il
            miglioramento dei prodotti Measure e Advertise. Ad esempio, queste
            aziende potrebbero contribuire a hosting, elaborazione dei dati,
            gestione dei database e attivit&agrave; amministrative. Queste terze
            parti e affiliate possono avere accesso ai Dati di log e ai Dati
            personali dell&rsquo;utente (se l&rsquo;utente ha inviato tali dati
            a Quantcast), ma solo per eseguire le suddette attivit&agrave; per
            conto dell&rsquo;utente e con l&rsquo;obbligo di non divulgarli o
            utilizzarli per altri scopi.
          </p>
          <p>
            Quantcast riceve inoltre dati demografici e di report di interessi
            sul Sito Quantcast da uno dei propri provider di servizi, Google
            Analytics. Questi dati vengono utilizzati esclusivamente per
            soddisfare la curiosit&agrave; di Quantcast riguardo al tipo e alla
            qualit&agrave; di tali dati demografici e report di interessi
            forniti da Google. Se l&rsquo;utente lo desidera, pu&ograve;
            rifiutare esplicitamente l&rsquo;utilizzo di Google Analytics
            attraverso le impostazioni sugli annunci di Google.
          </p>
          <p>
            Informazioni divulgate ai fini della protezione di Quantcast e di
            altri
          </p>
          <p>
            La politica di Quantcast prevede la protezione dalle violazioni
            della privacy degli utenti attraverso l&rsquo;abuso del sistema
            legale. Tuttavia, Quantcast si riserva il diritto di divulgare
            informazioni sull&rsquo;utente a ufficiali pubblici o responsabili
            dell&rsquo;applicazione della legge o a privati qualora Quantcast, a
            propria esclusiva discrezione, lo ritenga necessario per: (i)
            rispettare la conformit&agrave; a qualsiasi legge o normativa in
            vigore e a citazioni, ordinanze giudiziarie e mandati validi; (ii)
            proteggere i diritti, la propriet&agrave; e la sicurezza di
            Quantcast stessa, di terze parti o del pubblico in generale; e (iii)
            impedire o arrestare attivit&agrave; considerate da Quantcast
            illegali o non etiche.
          </p>
          <p>
            Informazioni divulgate in caso di trasferimento di propriet&agrave;
          </p>
          <p>
            Quantcast pu&ograve; vendere, trasferire o altrimenti condividere
            alcune o tutte le proprie risorse, inclusi i Dati di log e i Dati
            personali dell&rsquo;utente, in relazione a una fusione,
            un&rsquo;acquisizione, una riorganizzazione, una vendita di risorse
            o in caso di bancarotta. Quantcast pu&ograve; anche rendere
            disponibili determinati Dati di log o Dati personali a potenziali
            investitori o acquirenti (o ai loro consulenti) anticipatamente
            rispetto al completamento di una di tali transazioni.
          </p>
          <p>Combinazione dei dati</p>
          <p>
            Quantcast non correla i Dati di log ai Dati personali
            dell&rsquo;utente di cui pu&ograve; disporre n&eacute; tenta in
            altri modi di individuare l&rsquo;identit&agrave; dell&rsquo;utente.
            Quantcast impiega appropriate misure di sicurezza tecniche e
            organizzative per mantenere sicuri i Dati di log dell&rsquo;utente e
            per evitare che vengano correlati ai Dati personali
            dell&rsquo;utente.
          </p>
          <p>Associazioni multipiattaforma</p>
          <p>
            Ai Partner Quantcast che gestiscono siti Web e app per dispositivi
            mobile Quantcast fornisce un servizio di report multipiattaforma. A
            questo scopo, Quantcast si affida a identificatori con hash (ovvero
            criptati) derivati dagli accessi degli utenti per associare
            l&rsquo;utilizzo dell&rsquo;utente su diverse piattaforme mobile e
            desktop. In questo modo, il prodotto Measure &egrave; in grado di
            fornire report significativi tra diverse piattaforme per un singolo
            Partner. Talvolta Quantcast si affida inoltre ai Dati di log per
            effettuare associazioni multipiattaforma.
          </p>
          <p>Politiche di conservazione dei dati</p>
          <p>
            Quantcast conserva i Dati di log per 13 mesi per soddisfare i
            requisiti di stagionalit&agrave;. I dati vengono quindi eliminati in
            modo permanente. Questo &egrave; il pi&ugrave; lungo periodo di
            conservazione dei Dati di log raccolti attraverso i prodotti Measure
            e Advertise.
          </p>
          <p>Rifiuto esplicito</p>
          <p>
            Qualora l&rsquo;utente preferisca non ricevere contenuto basato
            sugli interessi e gli annunci attivati da Quantcast, pu&ograve;
            rifiutare esplicitamente tali contenuti facendo clic sul
            collegamento corrispondente qui https://www.quantcast.com/opt-out/
            Quantcast &egrave; inoltre un membro della Network Advertising
            Initiative (NAI) e partecipa al programma di autoregolamentazione
            della Digital Advertising Alliance (DAA). Questi programmi
            forniscono una funzionalit&agrave; di rifiuto esplicito tramite cui
            i consumatori possono scegliere di non ricevere pubblicit&agrave;
            mirata da qualsiasi affiliata NAI o DAA. Questi collegamenti
            reindirizzano alle opzioni di rifiuto esplicito NAI
            http://optout.networkadvertising.org/#!/ e di rifiuto esplicito DAA
            http://optout.aboutads.info/#!/. Gli utenti dell&rsquo;Unione
            Europea possono scegliere di utilizzare l&rsquo;opzione di rifiuto
            esplicito EDAA http://www.youronlinechoices.eu. Dopo che
            l&rsquo;utente ha indicato il proprio rifiuto esplicito, Quantcast
            aggiunger&agrave; al suo browser un cookie che lo identifica come
            utente che ha scelto di non ricevere tali materiali. L&rsquo;utente
            non ricever&agrave; pi&ugrave; contenuto basato sugli interessi o
            annunci attivati da Quantcast e Quantcast non registrer&agrave;
            pi&ugrave; le abitudini dell&rsquo;utente in termini di visite dei
            siti Web o di utilizzo di contenuti multimediali online.
            Poich&eacute; il rifiuto esplicito &egrave; basato su cookie, il
            browser dell&rsquo;utente deve essere impostato per
            l&rsquo;accettazione di cookie di terze parti. Se l&rsquo;utente
            utilizza pi&ugrave; dispositivi o browser, dovr&agrave; impostare il
            proprio rifiuto esplicito su ciascuno di essi. Se l&rsquo;utente
            elimina il cookie, dovr&agrave; indicare nuovamente il proprio
            rifiuto esplicito.
          </p>
          <p>
            Accesso, modifica o eliminazione delle informazioni
            dell&rsquo;utente
          </p>
          <p>
            L&rsquo;utente ha il diritto di accedere ai propri Dati personali
            controllati da Quantcast scrivendo agli indirizzi e-mail pertinenti,
            identificati nella sezione &ldquo;Contatti&rdquo; di questo link:{" "}
            <a href="https://www.quantcast.it/privacy/">
              https://www.quantcast.it/privacy/
            </a>{" "}
            Quantcast si riserva il diritto (soggetto alla legge in vigore) di
            rifiutare richieste eccessive o che ledono la privacy altrui. Un
            Utente registrato pu&ograve; esaminare, aggiornare, correggere o
            eliminare i Dati personali presenti nel profilo di registrazione
            accedendo al proprio account. I Dati di log non possono essere
            corretti o eliminati dall&rsquo;utente. Qualora desideri eliminare
            il proprio account, l&rsquo;utente dovr&agrave; contattare
            Quantcast. Quantcast potrebbe mantenere una copia archiviata dei
            record dell&rsquo;utente in base a quanto richiesto dalla legge o
            per tutto il tempo necessario agli scopi per cui tali record sono
            stati raccolti o ulteriormente elaborati ai sensi della legge.
          </p>
          <p>Sicurezza dei dati</p>
          <p>
            Quantcast adotta misure amministrative, fisiche e tecniche concepite
            per proteggere le informazioni dell&rsquo;utente dall&rsquo;accesso
            non autorizzato. Tali misure possono includere la crittografia delle
            informazioni fornite dall&rsquo;utente e l&rsquo;impiego di
            tecnologie di sicurezza per la memorizzazione delle informazioni in
            modo da bloccare l&rsquo;accesso esterno alla rete Quantcast.
            L&rsquo;utente dovr&agrave; tenere presente che nessun metodo di
            trasmissione delle informazioni su Internet o di memorizzazione
            delle informazioni &egrave; completamente sicuro. Inoltre, in alcuni
            casi i Partner o altre parti potrebbero fornire a Quantcast
            informazioni in forma non crittografata.
          </p>
          <p>Trasferimenti internazionali</p>
          <p>
            I Dati di log e i Dati personali dell&rsquo;utente potrebbero essere
            trasferiti o gestiti in computer situati al di fuori
            dell&rsquo;area, della provincia o del paese (o altro)
            dell&rsquo;utente, in cui le leggi sulla privacy potrebbero non
            essere altrettanto protettive di quelle in vigore nella
            giurisdizione dell&rsquo;utente. Se l&rsquo;utente si trova in un
            paese diverso dagli Stati Uniti e sceglie di fornire informazioni a
            Quantcast, dovr&agrave; tenere presente che i suoi Dati personali
            verranno trasferiti ed elaborati negli Stati Uniti. Il consenso a
            questa Informativa sulla privacy, seguito dall&rsquo;invio di tali
            informazioni, rappresenta l&rsquo;accettazione da parte
            dell&rsquo;utente di tale trasferimento.
          </p>
          <p>Politiche Quantcast riguardo ai minori</p>
          <p>
            Quantcast non raccoglie intenzionalmente Dati personali di bambini
            di et&agrave; inferiore a 13 anni. Se un genitore o un tutore di un
            bambino viene a conoscenza del fatto che questi ha fornito Dati
            personali senza il proprio consenso, dovr&agrave; contattare
            Quantcast. Qualora Quantcast si renda conto che un bambino di
            et&agrave; inferiore a 13 anni ha fornito Dati personali,
            eliminer&agrave; tali informazioni dai propri file.
          </p>
          <p>Informazioni sulle condizioni di salute</p>
          <p>
            Quantcast non raccoglie o utilizza intenzionalmente alcuna
            informazione riservata correlata alla salute, quali, ad esempio,
            informazioni relative a condizioni o prescrizioni mediche passate o
            presenti. Quantcast fornisce tuttavia supporto ad alcuni
            inserzionisti suoi clienti nel presentare annunci per prodotti
            correlati a salute, medicina o benessere. Quantcast fornisce inoltre
            i seguenti segmenti standard correlati a salute e benessere:
            prodotti cosmetici, pannolini, cura dei capelli e igiene orale.
          </p>
          <p>Altri siti Web e app</p>
          <p>
            Quantcast richiede ai propri Partner mediante contratto di garantire
            che i siti e le app da essi forniti e contenenti Tag pubblichino
            informative sulla privacy adeguate e proteggano altrimenti i diritti
            alla privacy dei visitatori. Tuttavia, il fatto che Quantcast
            consenta il collegamento a un&rsquo;app o a un sito specifico o
            riceva dati da un&rsquo;app o un sito non significa che Quantcast
            approvi tale app o sito, n&eacute; le politiche sulla privacy
            correlate. Tali siti Web adottano condizioni di utilizzo e
            informative sulla privacy proprie e Quantcast invita l&rsquo;utente
            a leggere tali condizioni e informative durante la propria visita.
          </p>
          <p>Controllo dei dati Quantcast</p>
          <p>
            Il Sito e i Servizi di Quantcast.com vengono resi disponibili e
            forniti alle persone che risiedono negli Stati Uniti da Quantcast
            Corporation, all&rsquo;indirizzo 795 Folsom Street, San Francisco,
            CA 94107. Le informazioni o i dati correlati alle persone che
            risiedono negli Stati Uniti forniti ai o raccolti dai Siti o Servizi
            di Quantcast.com vengono controllati da Quantcast Corporation.
          </p>
          <p>
            I Siti e i Servizi di Quantcast.com vengono resi disponibili alle
            persone residenti al di fuori degli Stati Uniti da Quantcast
            International Limited, Beaux Lane House, Lower Mercer Street, 1st
            Floor, Dublin 2, Ireland. Le informazioni o i dati correlati alle
            persone che risiedono al di fuori degli Stati Uniti forniti ai o
            raccolti dai Siti o Servizi di Quantcast.com vengono controllati da
            Quantcast International Limited.
          </p>
          <p>GOOGLE</p>
          <p>
            Per attivare campagne advertising in retargeting viene fornito un
            pixel di retargeting Google che viene inserito all&rsquo;interno del
            sito del cliente. Dopo avere aggiunto il tag al sito web o
            all&rsquo;app, &egrave; possibile creare gli elenchi per il
            remarketing per i visitatori del sito.
            <br /> Quando gli utenti visitano il sito, il cookie con il loro ID
            viene aggiunto all&rsquo;elenco per il remarketing.
            <br /> E&rsquo; possibile in questo modo creare una campagna con un
            messaggio specifico da mostrare agli utenti inclusi
            nell&rsquo;elenco mentre eseguono ricerche su Google o esplorano
            siti web della Rete Display.
          </p>
          <p>
            Per ulteriori dettagli sulla tipologia di Cookie utilizzati da
            Google fare riferimento al paragrafo relativo.
          </p>
          <p>FACEBOOK</p>
          <p>
            Per attivare campagne advertising in retargeting Alleanza luce &amp;
            gas fornisce un pixel di Facebook (creato dal Power Editori di
            Facebook) che il Cliente deve inserire all&rsquo;interno del suo
            sito web.
            <br />{" "}
          </p>
          <p>
            Quando un utente visita il sito web del Cliente ed esegue
            un&rsquo;azione (ad es. acquista qualcosa), il pixel di Facebook si
            attiva e registra tale azione. In questo modo &egrave; possibile
            sapere quando un utente ha eseguito un&rsquo;azione dopo aver visto
            l&rsquo;inserzione di Facebook messa online da Alleanza luce &amp;
            gas. Inoltre &egrave; possibile raggiungere di nuovo questo utente
            attivando campagne di retargeting su un pubblico personalizzato.
            Pi&ugrave; aumentano le conversioni sul sito web, migliore
            sar&agrave; la capacit&agrave; di Facebook di mostrare le inserzioni
            alle persone che potrebbero eseguire determinate azioni.
            <br /> Si tratta della cosiddetta ottimizzazione delle conversioni.
          </p>
          <p>
            Qui{" "}
            <a href="https://www.facebook.com/business/help/952192354843755">
              https://www.facebook.com/business/help/952192354843755
            </a>{" "}
            sono presenti le istruzioni dettagliate sull&rsquo;aggiunta del
            pixel di Facebook al sito web.
          </p>
          <p>
            Prima di poter attivare e iniziare a utilizzare il retargeting di
            Facebook, occorre accettare i termini di Facebook:
          </p>
          <ul>
            <li>
              Facebook user&agrave; i Dati sulle azioni ricevuti per offrire
              all&rsquo;utente i servizi di Facebook richiesti, ad esempio per
              fornire dati statistici sull&rsquo;efficacia delle inserzioni e
              sull&rsquo;utilizzo del sito Web o dell&rsquo;app
              dell&rsquo;utente o per creare il pubblico personalizzato per le
              inserzioni e in conformit&agrave; con la Normativa sui dati
              (https://www.facebook.com/about/privacy/);
            </li>
            <li>
              i Dati sulle azioni consentiranno inoltre di definire in modo
              pi&ugrave; appropriato i destinatari delle inserzioni e
              ottimizzare i sistemi. In relazione a tali attivit&agrave; di
              targetizzazione e ottimizzazione, Facebook: usa i Dati sulle
              azioni raccolti dal sito Web o dall&rsquo;app mobile
              dell&rsquo;utente per l&rsquo;ottimizzazione delle inserzioni solo
              dopo che i Dati sulle azioni vengono aggregati agli altri dati
              raccolti da altri inserzionisti o terzi o raccolti in altro modo
              su Facebook e non consente ad altri inserzionisti o a terzi di
              definire i destinatari delle inserzioni solo sulla base dei Dati
              sulle azioni raccolti dal sito Web o dall&rsquo;app mobile
              dell&rsquo;utente.
            </li>
            <li>
              i Dati sulle azioni non saranno rivelati ad altri inserzionisti o
              a terzi, a meno che Facebook non disponga
              dell&rsquo;autorizzazione dell&rsquo;utente o non debba farlo per
              legge. Facebook garantisce la riservatezza e la sicurezza dei Dati
              sulle azioni provvedendo, tra l&rsquo;altro, a mantenere misure di
              protezione tecnica e fisica volte a tutelare la sicurezza e
              l&rsquo;integrit&agrave; dei dati mentre si trovano
              all&rsquo;interno dei sistemi di Facebook e proteggere i dati da
              accesso, uso, alterazione o divulgazione accidentali o non
              autorizzati all&rsquo;interno dei sistemi di Facebook.
            </li>
          </ul>
          <p>
            Qui{" "}
            <a href="https://www.facebook.com/customaudiences/app/tos/?ref=u2u">
              https://www.facebook.com/customaudiences/app/tos/?ref=u2u
            </a>{" "}
            sono presenti le condizioni per il monitoraggio delle conversioni,
            il pubblico personalizzato dal sito Web e il pubblico personalizzato
            dall&rsquo;app mobile.
          </p>
          <hr /> <br />
          <h4>
            <strong>EMAIL MARKETING</strong>
          </h4>
          <br/>
          <p>
            L&rsquo;E-mail marketing &egrave; un tipo di{" "}
            <a href="https://it.wikipedia.org/wiki/Direct_marketing">
              marketing diretto
            </a>{" "}
            che usa la{" "}
            <a href="https://it.wikipedia.org/wiki/Posta_elettronica">
              posta elettronica
            </a>{" "}
            come mezzo per comunicare messaggi, commerciali e non, al pubblico.{" "}
          </p>
          <p>
            In senso lato, qualunque e-mail inviata a un cliente (o cliente
            potenziale), pu&ograve; essere considerata un&rsquo;azione di e-mail
            marketing.
          </p>
          <p>
            Solitamente per&ograve; si usa il termine{" "}
            <strong>Newsletter </strong>quando l&rsquo;invio della comunicazione
            email viene inviata ad un database di indirizzi di propriet&agrave;
            del cliente (ovvero alla banca dati di gi&agrave; clienti), mentre
            si usa il termine <strong>DEM</strong> (Direct Email Marketing) per
            l&rsquo;attivit&agrave; pubblicitaria online basata sull&rsquo;invio
            di un messaggio pubblicitario tramite email verso una lista di
            utenti appartenente ad un database terzo, che viene
            &ldquo;affittata&rdquo; (e quindi pagata al proprietario del
            database) per l&rsquo;invio della comunicazione.
          </p>
          <p>
            Alleanza luce &amp; gas gestisce attivit&agrave; di Newsletter:
            acquisisce quindi il databse dei clienti del proprio cliente, lo
            profila, costruisce il messaggio grafico e testuale oggetto della
            comunicazione, profila la lista di invio sulla base dei dati
            associati all&rsquo;indirizzo email (es. sesso, et&agrave;,
            citt&agrave; di residenza, interessi espressi) e lo invia tramite un
            apposito software (Mailup).
          </p>
          <p>
            Alleanza luce &amp; gas gestisce anche attivit&agrave; di DEM:
            noleggia database di indirizzi email da fornitori terzi, costruisce
            il messaggio grafico e testuale oggetto della comunicazione, e
            chiede al fornitore l&rsquo;invio tramite il suo apposito software.
            I fornitori con cui Alleanza luce &amp; gas collabora per questa
            tipologia di attivit&agrave; sono Indicta, AdviceMe, Adsalsa,
            Bakeca.it, Add Communication, WeryBiz Srls, 4DEM, TukTuk, MajorDem,
            Ketchup, Italiaonline, PayClick, Leonardo Adv, Triboo Media, Lead
            Leaders, ValueDem, Chiarezza.
          </p>
          <hr /> <br />
          <h4>
            <strong>OTHER</strong>
          </h4>
          <br/>
          <p>
            <strong>INSTAPAGE</strong>
          </p>
          <p>
            Alleanza luce &amp; gas utilizza la piattaforma{" "}
            <strong>Instapage</strong> per la generazione di pagine di
            atterraggio per le campagne pubblicitarie online.{" "}
          </p>
          <p>L&rsquo;obiettivo della pagina pu&ograve; essere:</p>
          <ol>
            <li>
              portare l&rsquo;utente a compilare un modulo (form) di contatto
            </li>
            <li>
              indurre l&rsquo;utente ad acquistare direttamente sul sito del
              cliente (nei casi in cui &egrave; possibile la vendita online)
            </li>
          </ol>
          <p>
            Nel primo caso (obiettivo compilazione di un modulo di contatto), la
            pagina include un apposito form. I dati inseriti dall&rsquo;utente
            nel form vengono salvati all&rsquo;interno di Instapage o inviati al
            CRM del cliente.
            <br /> Se vengano salvati all&rsquo;interno di Instapage, viene
            schedulato da Alleanza luce &amp; gas un invio automatico al cliente
            (che avviene attraverso un API) dell&rsquo;export contenente i dati
            inseriti dall&rsquo;utente all&rsquo;interno del modulo. I dati
            raccolti attraverso Instapage sono quindi potenzialmente visibili
            dai dipendenti Alleanza luce &amp; gas in possesso degli accessi
            alla piattaforma.
          </p>
          <p>Informazioni sensibili visibili:</p>
          <ul>
            <li>Nome = in chiaro</li>
            <li>Cognome = in chiaro</li>
            <li>Paese = in chiaro</li>
            <li>Citt&agrave; = in chiaro</li>
            <li>Mail = in chiaro</li>
            <li>Numero di telefono = in chiaro</li>
          </ul>
          <p>
            Il beautifier gratuito del codice CSS online prende cura del tuo
            codice sporco e elimina ogni pasticcio indesiderato. Vai a{" "}
            <a href="https://html-cleaner.com/css/" target="_blank">
              CSS Cleaner
            </a>{" "}
            per iniziare.
          </p>
        </div>
      </Accordion>
    </div>
  </General>
);

Policy.propTypes = propTypes;
Policy.defaultProps = defaultProps;

export { Policy };
