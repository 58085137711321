const root_config = () =>{
  const ENV = process.env.REACT_APP_TYPE;
  let publicUrl = "https://attiva.accendilucegas.it/api/";
  switch (ENV) {
    case 'local':
      publicUrl = 'http://localhost:5000/api/';
      break;
    case 'stage':
      publicUrl = 'https://preprod.accendilucegas.it/api/';
      break;
    case 'production':
      publicUrl = 'https://attiva.accendilucegas.it/api/';
      break;
  }
  return {
    upload_url: '',
    audio_folder: '',
    url: publicUrl,
    modalTimeout: 600
  };
}

module.exports = root_config;

