import React from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

import {Icon} from '../../atoms/icon';

import style from './breadcrumb.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const Breadcrumb = ({
  label,
  url,
  className,
  ...otherProps
}) => (
  <div className={classnames(style.breadcrumb, className)} {...otherProps}>
    <Icon className={style.icon} name='arrow'></Icon>
    <Link to={url} >{label}</Link>
  </div>
);

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

export { Breadcrumb };
