import React, {useState, useRef, useEffect} from 'react';
import classnames from 'classnames';
import { Formik, Form, Field } from 'formik';
import style from './truffe.scss';
import { Button } from '../../components/ui/atoms/button';
import { Input } from '../../components/ui/atoms/input';
import { General } from '../../components/template/general';
import { Header } from '../../components/ui/molecules/header';
import ReactTooltip from 'react-tooltip'
import {Icon} from '../../components/ui/atoms/icon'
import axios from 'axios'
import rootConfig from '../../core/config/root-config.js'
import { Redirect } from 'react-router-dom';
import { Modal } from '../../components/modal';
import { GeneralStatus as GeneralStatusModal } from '../../components/modal/general_status';
import { routes } from '../../core/config/routes';

const Truffe = () => {
  const refContent = useRef(null);
  const [inputFocused               , setInputFocused]                  = useState(false);
  const [isMobile                   , setIsMobile]                      = useState(window.innerWidth < 750);
  const [name_is_valid              , set_name_is_valid]                = useState(null)
  const [surname_is_valid           , set_surname_is_valid]             = useState(null)
  const [fiscal_code_is_valid       , set_fiscal_code_is_valid]         = useState(null)
  const [phone_number_is_valid      , set_phone_number_is_valid]        = useState(null)
  const [time_is_valid              , set_time_is_valid]                = useState(null)
  const [date_is_valid              , set_date_is_valid]                = useState(null)
  const [company_name_is_valid      , set_company_name_is_valid]        = useState(null)
  const [email_is_valid             , set_email_is_valid]               = useState(null)
  const [name_touched               , setName_touched]                  = useState(false)
  const [surname_touched            , setSurname_touched]               = useState(false)
  const [cf_touched                 , setCf_touched]                    = useState(false)
  const [email_touched              , setEmail_touched]                 = useState(false)
  const [phoneNumberDisabled        , setPhoneNumberDisabled]           = useState(false)
  const [thanks                     , setThanks]                        = useState(false)

  const [modalState_status          , set_modalState_status]    = useState({'general_state': false});
  const [status_modal_key           , set_status_modal_key]     = useState('general_state');

  const date = new Date();
  const hour = date.getHours()
  const minute = date.getMinutes()
  const day = date.getDate();
  const month = Number(date.getMonth()) +1;
  const year = date.getFullYear();

  useEffect(() => {

    function handleWindowResize() {
      setIsMobile(window.innerWidth < 750);
    }

    // Add event listener
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  });

  const modalState_status_close = () => {
    set_modalState_status({ 'general_state': false });
  }

  const handleSubmit = async(data)=>{
    data.email = data.email.trim()
    localStorage.setItem('email', data.email)
    if(data.unknown_number && data.unknown_number == true) data.phone_number = 0
    data.new_offer = (data.new_offer) ? true : false;
    data.home_visit = (data.home_visit) ? true : false;
    data.information_request = (data.information_request) ? true : false;
    data.unknown_number = (data.unknown_number) ? true : false;

    console.log('[TEST]', data)
    return axios({
      method: 'post',
      url: rootConfig().url + 'frauds',
      timeout: 3000,
      data
    }).then((data)=>{
      return setThanks(true)
    }).catch((err)=>{
      return set_modalState_status({'general_state': true})
    });
  }

  function handleBlurInput() {
    if (isMobile) {
      setInputFocused(false);
    }
  }

  function validator(values){
    const reg_cf = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]{1,}$/i;
    const reg_email = /^\s*[a-zA-Z0-9_.+-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,}\s*$/
    const reg_name = /^[a-zA-Zèéòàùì ]{1,}$/;
    const errors    = {};

    for (let key in values) {
      if (key === 'name') {
        if (reg_name.test(values[key])) {
          set_name_is_valid('valid')
        } else if(values[key].length < 1 && name_touched == true){
          errors[key] ='Il nome è richiesto'
          set_name_is_valid('invalid');
        } else if (name_touched == true){
          errors[key] = 'Inserito carattere non valido';
          set_name_is_valid('invalid');
        }
        continue
      }
      if (key === 'surname') {
        if (reg_name.test(values[key])) {
          set_surname_is_valid('valid')
        } else if(values[key].length < 1 && surname_touched == true ){
          errors[key] ='Il cognome è richiesto'
          set_surname_is_valid('invalid');
        } else  if (surname_touched == true){
          errors[key] = 'Inserito carattere non valido';
          set_surname_is_valid('invalid');
        }
        continue
      }
      if(key === 'fiscal_code'){
        if (values[key].length == 16 && reg_cf.test(values[key])){
          set_fiscal_code_is_valid('valid')
        } else if(values[key].length < 1 && cf_touched == true){
          errors[key] ='Il codice fiscale è richiesto'
          set_fiscal_code_is_valid('invalid')
        } else if (cf_touched == true){
          errors[key] = 'Il codice fiscale non è valido'
          set_fiscal_code_is_valid('invalid')
        }
        continue
      }
      if(key === 'phone_number'){
        if (values[key] >= 100000000 && values[key] <= 9999999999){
          set_phone_number_is_valid('valid')
        } else {
          errors[key] = 'Numero di telefono non valido'
          set_phone_number_is_valid('invalid')
        }
        if(values[key] == ''){
          set_phone_number_is_valid(null)
          errors[key] = null
        }
        continue
      }
      if(key === 'time_call'){
        const dateCall = values['date_call'].split('-')
        const yearCall = Number(dateCall[0])
        const monthCall = Number(dateCall[1])
        const dayCall = Number(dateCall[2])
        if (yearCall == year && monthCall == month && dayCall == day){
          if(values[key] != ''){
            const time = values[key].split(':')
            if(time[0] > hour){
              set_time_is_valid('invalid')
              errors[key] = 'Sei nel futuro'
            }else if(time[0] == hour && time[1] > minute){
              set_time_is_valid('invalid')
              errors[key] = 'Sei nel futuro'
            }else{
              if(values[key]!=='')
              set_time_is_valid('valid')
          }
        }
        }else{
          if(values[key]!=='')
          set_time_is_valid('valid')
        }
        continue
      }
      if(key === 'date_call'){
        const dateCall = values[key].split('-')
        const yearCall = Number(dateCall[0])
        const monthCall = Number(dateCall[1])
        const dayCall = Number(dateCall[2])
        if(values[key]!==''){
          set_date_is_valid('valid')
        }
        if(values[key] == ''){
          values['time_call'] = ''
          set_time_is_valid(null)
          set_date_is_valid(null)
        }
        if(yearCall>year){
          set_date_is_valid('invalid')
          errors[key] = 'Sei nel futuro'
        } else if (yearCall === year){
          if(monthCall > month){
            set_date_is_valid('invalid')
            errors[key] = 'Sei nel futuro'
          } else if(monthCall == month){
            if(dayCall > day){
              set_date_is_valid('invalid')
              errors[key] = 'Sei nel futuro'
            }
          }
        }
        continue
      }
      if(key == 'company_name'){
        if (values[key].length >= 3){
          set_company_name_is_valid('valid')
        } else {
          set_company_name_is_valid(null)
        }
        continue
      }
      if (key === 'email') {
        if (reg_email.test(values[key])) {
          set_email_is_valid('valid')
        } else  if(values[key].length < 1 == email_touched == true){
          errors[key] ='email richiesta'
          set_email_is_valid('invalid')
        } else if (email_touched == true){
          errors[key] = 'Formato email non corretto';
          set_email_is_valid('invalid');
        }
        continue
      }
      if (key === 'unknown_number') {
        if (values[key] == true) {
          setPhoneNumberDisabled(true)
          set_phone_number_is_valid(null)
          delete errors.phone_number
        } else {
          setPhoneNumberDisabled(false)
        }
      }
    }
    return errors
  }

  function handleFocusInput() {
    if (isMobile) {
      refContent.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      setInputFocused(true);
    }
  }
  return (
    <General
    logoCenter={true}
    // className={style.top}
    >

      <div className={style.container}>
              <div className={style.header}>
                <Header
                  variant='big'
                  title={'Accendi'}
                  title2={'l\'attenzione'}
                  subtitle={'Compila il modulo sottostante'}
                  subtitle2={'per segnalarci attività illecite.'}
                />
              </div>
              <br/>
              <div className={style.justify}>
                <span>
                  Caro cliente, di seguito trovi la scheda di segnalazione dove ti chiediamo di inserire i dati obbligatori e di spiegare con la maggior precisione possibile quanto accaduto in relazione al contatto telefonico ricevuto da parte di società terze o di persone che si sono finte operatrici di “Alleanza Luce & Gas”.
                  La tua scheda di segnalazione ci consente di migliorare il nostro servizio e di porre fine a eventuali azioni di disturbo da parte di persone o società non autorizzate.
                  E’ importante infatti che tu sappia che questi dati vengono utilizzati sia per scopi statistici sia per fermare eventuali truffe ai danni dei nostri clienti; pertanto, laddove il comportamento di  presunti operatori o società sia stato lesivo della tua persona o della nostra Società, potrai eventualmente esser chiamato in qualità di persona informata sui fatti o come testimone.
                  Ricorda sempre che nessun consulente Accendi luce & gas  è autorizzato a bussare alla tua porta per proporti nuovi contratti o modifiche a quello sottoscritto o a chiamarti a casa, se non hai compilato volontariamente il nostro form di contatto lasciando il tuo numero di telefono.

                  Prima di fornire i dati richiesti, leggi l'informativa sul trattamento dei tuoi dati personali, che trovi
                </span>
                <a href="https://www.accendilucegas.it/modulistica-file/?f=ACCENDI-privacy/Nota-informativa-PRIVACY.pdf" target="_blank" className={style.pdfPrivacyLink}>qui</a>
              </div>
      </div>

      <Formik
        initialValues={{
          name: '',
          surname: '',
          fiscal_code: '',
          pod_pdr: '',
          prefix_phone:'+39',
          phone_number:'',
          unknown_number:'',
          date_call:'',
          time_call:'',
          new_offer:'',
          home_visit:'',
          information_request:'',
          company_name:'',
          isPersonCoop:'',
          description:'',
          email:''
        }}
        validate={validator}
        onSubmit={handleSubmit}
      >
      {({ isSubmitting, errors }) => (
        <Form >
          {/* <div className={classnames(style.wrapperLarger, style.container)}>
            {window.screen.width >= 450 ? (
            <div className={style.largerHeader}>
              <LargerHeader onLinkPress={()=> console.log('fatto')} title={'Cosa è successo?'} title2={'Raccontaci'} subtitle={'Caro cliente, di seguito trovi la scheda di segnalazione dove ti chiediamo di inserire i dati obbligatori e di spiegare con la maggior precisione possibile quanto accaduto in relazione al contatto telefonico ricevuto da parte di società terze o di persone che si sono finte operatrici di “Alleanza Luce & Gas”.La tua scheda di segnalazione ci consente di migliorare il nostro servizio e di porre fine a eventuali azioni di disturbo da parte di persone o società non autorizzate. E’ importante infatti che tu sappia che questi dati vengono utilizzati sia per scopi statistici sia per fermare eventuali truffe ai danni dei nostri clienti; pertanto, laddove il comportamento di  presunti operatori o società sia stato lesivo della tua persona o della nostra Società, potrai eventualmente esser chiamato in qualità di persona informata sui fatti o come testimone.Ricorda sempre che nessun consulente Accendi luce & gas  è autorizzato a bussare alla tua porta per proporti nuovi contratti o modifiche a quello sottoscritto o a chiamarti a casa, se non hai compilato volontariamente il nostro form di contatto lasciando il tuo numero di telefono. Prima di fornire i dati richiesti, leggi l\'informativa sul trattamento dei tuoi dati personali, che trovi '} link={'qui'}/>
            </div>
            ):(
              <div className={style.largerHeader}>
              <LargerHeader onLinkPress={()=> console.log('fatto')} title={'Cosa è successo?'} title2={'Raccontaci'} subtitle={'Caro cliente, di seguito trovi la scheda di segnalazione dove ti chiediamo di inserire i dati obbligatori e di spiegare con la maggior precisione possibile quanto accaduto in relazione al contatto telefonico ricevuto da parte di società terze o di persone che si sono finte operatrici di “Alleanza Luce & Gas”.La tua scheda di segnalazione ci consente di migliorare il nostro servizio e di porre fine a eventuali azioni di disturbo da parte di persone o società non autorizzate. E’ importante infatti che tu sappia che questi dati vengono utilizzati sia per scopi statistici sia per fermare eventuali truffe ai danni dei nostri clienti; pertanto, laddove il comportamento di  presunti operatori o società sia stato lesivo della tua persona o della nostra Società, potrai eventualmente esser chiamato in qualità di persona informata sui fatti o come testimone.Ricorda sempre che nessun consulente Accendi luce & gas  è autorizzato a bussare alla tua porta per proporti nuovi contratti o modifiche a quello sottoscritto o a chiamarti a casa, se non hai compilato volontariamente il nostro form di contatto lasciando il tuo numero di telefono. Prima di fornire i dati richiesti, leggi l\'informativa sul trattamento dei tuoi dati personali, che trovi '} link={'qui'}/>
            </div>
            )}
          </div> */}
          <div className={style.wrapper}>
            <div className={style.container}>
              <h2 className={style.titleForm}>
                Dati anagrafici
              </h2>
              <p className={style.subtitleObbligation}>I campi contrassegnati da asterisco* sono obbligatori</p>
              <div ref={refContent}>

                <Input
                  className={style.marginBottom}
                  name='name'
                  label='Nome del titolare della fornitura *'
                  required
                  status={name_is_valid}
                  placeholder='Inserisci il nome'
                  type='text'
                  visible='don_t_show_eye'
                  handleFocus={()=>{
                    setName_touched(true)
                    handleFocusInput()}}
                  handleBlur={()=>{
                    handleBlurInput()
                    validator()
                  }}
                  showerror={false}
                  errormsg={errors.name}
                />
                <Input
                  className={style.marginBottom}
                  name='surname'
                  label='Cognome del titolare della fornitura *'
                  required
                  placeholder='Inserisci il cognome'
                  status={surname_is_valid}
                  type='text'
                  visible='don_t_show_eye'
                  handleFocus={()=>{setSurname_touched(true)}}
                  handleBlur={handleBlurInput}
                  showerror={true}
                  errormsg={errors.surname}
                />
                <Input
                  className={style.marginBottom}
                  name='fiscal_code'
                  status={fiscal_code_is_valid}
                  label='Codice fiscale *'
                  placeholder='Inserisci il codice fiscale'
                  visible='don_t_show_eye'
                  showerror={true}
                  type='text'
                  onClick={()=>{}}
                  handleFocus={()=>{setCf_touched(true)}}
                  handleBlur={handleBlurInput}
                  errormsg={errors.fiscal_code}
                />

                {isMobile === true ? (
                  <Input
                    className={style.marginBottom}
                    name='pod_pdr'
                    label='POD/PDR'
                    // status={pod_is_valid}
                    placeholder='Inserisci il tuo POD o PDR della fornitura'
                    type='text'
                    status='info'
                    visible='don_t_show_eye'
                    handleBlur={handleBlurInput}
                  />
                ):(
                  <div>
                    <Input
                      label={
                        <div className={style.labelPod}>
                        <p className={style.label}> POD-PDR</p>
                        <a data-tip data-for='global'><Icon className={style.icon} name='info' /></a>
                      </div>
                      }
                      className={style.marginBottom}
                      name='pod_pdr'
                      placeholder='Inserisci il tuo POD o PDR della fornitura'
                      type='text'
                      visible='don_t_show_eye'
                      handleBlur={handleBlurInput}
                    />
                    <ReactTooltip className={style.tooltip} id="global" place="top" effect='solid' type="ligth" >
                      <div>
                        Qualora il Cliente abbia più contratti si prega di precisare per quale POD/PdR è stato contattato.
                        Il POD (Point of Delivery) indica il punto fisico
                        di consegna di energia elettrica e il PDR
                        (Punto di riconsegna) specifica il punto fisico di
                         consegna del gas. Entrambi sono codici univoci
                        nazionali di 14 caratteri che trovi facilmente
                        sulla tua bolletta.
                      </div>
                    </ReactTooltip>
                  </div>
                )}

              </div>
            </div>
          </div>
          <div className={classnames( style.wrapper, style.container)}>
            <div style={{marginBottom:15}}>
              <p className={style.titleForm}>
                Informazioni sull'accaduto
              </p>
            </div>
            <div style={{paddingLeft:20}}>
            <p className={style.label}>Da quale numero sei stato contattato?*</p>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
              <div style={{width:'30%'}}>
                <fieldset id='phonePref' disabled>
                <Input
                  style={{color:'black'}}
                  type='number'
                  name='prefix_phone'
                  type='text'
                  disabledStyle={phoneNumberDisabled}
                  warning={false}/>
                </fieldset>
              </div>
              <div style={{width:'80%', paddingLeft: '2%'}}>
                <fieldset id='phonePref' disabled={phoneNumberDisabled}>
                  <Input
                    className={style.name}
                    name='phone_number'
                    placeholder='Inserisci il numero che ti ha contattato'
                    type='number'
                    disabledStyle={phoneNumberDisabled}
                    onKeyDown={
                      (evt) => evt.key === 'e'  && evt.preventDefault() ||evt.key === '-'  && evt.preventDefault() || evt.key === '+'  && evt.preventDefault()  || evt.key === '.'  && evt.preventDefault()  || evt.key === ','  && evt.preventDefault()
                    }
                    status={phone_number_is_valid}
                    showerror={true}
                    handleBlur={handleBlurInput}
                    errormsg={errors.phone_number}
                  />
                </fieldset>
              </div>
            </div>
            <div className={style.checkboxGroup} style={{paddingBottom:20}}>
              <div className={style.checkboxContainer}>
                <Field
                  name='unknown_number'
                  type='checkbox'
                  id="unknownNumber"
                />
                <label htmlFor="unknownNumber">Il numero era anonimo</label>
              </div>
            </div>
            <div className={isMobile == true ? style.column : style.row}>
              <div className={isMobile == true ? style.inputDateBigger : style.inputDate}>
                <Input
                    className={style.marginBottom}
                    name='date_call'
                    type='date'
                    label='Data del contatto*'
                    visible='don_t_show_eye'
                    status={date_is_valid}
                    showerror={true}
                    handleBlur={handleBlurInput}
                    errormsg={errors.date_call}
                  />
              </div>
              <div className={isMobile == true ? style.inputTimeBigger : style.inputTime}>
                <Input
                  className={style.marginBottom}
                  name='time_call'
                  label='Ora del contatto*'
                  status={time_is_valid}
                  type='time'
                  visible='don_t_show_eye'
                  showerror={true}
                  handleBlur={handleBlurInput}
                  errormsg={errors.time_call}
                />
              </div>
            </div>
            <div style={{paddingBottom : 10}}>
            <p className={style.label}>Motivo del contatto *</p>
            <div className={style.checkboxGroup}>
              <div className={style.checkboxContainer}>
                <Field
                  name='new_offer'
                  type='checkbox'
                  id="newOffer"/>
                <label htmlFor="newOffer">Nuova offerta</label>
                <Field
                  name='home_visit'
                  type='checkbox'
                  id="homeVisit"
                />
                <label htmlFor="homeVisit"> Visita a domicilio </label>
                <Field
                  name='information_request'
                  type='checkbox'
                  id="info_request"
                />
                <label htmlFor="info_request">
                  Richiesta informazioni
                </label>
              </div>
            </div>
            </div>

            <Input
              className={style.marginBottom}
              name='company_name'
              placeholder="Inserisci il nome e/o l'azienda dell'operatore"
              label="Nome e/o azienda dell'operatore che ti ha contattato"
              status={company_name_is_valid}
              type='text'
              visible='don_t_show_eye'
              showerror={true}
              handleBlur={handleBlurInput}/>
              <p className={style.question}>Si è presentato come collaboratore di Accendi luce & gas?*</p>
            <div className={style.radioGroup}>
              <div className={style.radioContainer}>
                <Field
                component="input"
                type="radio"
                name="isPersonCoop"
                value={true}
                id="coopYes"
                className={style.radio} />
                <label htmlFor={'coopYes'}>
                  Sì
                </label>
              </div>
              <div className={style.radioContainer}>
                <Field
                component="input"
                type="radio"
                name="isPersonCoop"
                value={false}
                id="coopNo"/>
                <label htmlFor="coopNo">
                  No
                </label>
              </div>
            </div>
            <div style={{paddingTop:18}}>
            <p className={style.label}>Descrizione dettagliata dell'accaduto*</p>
            <Field
              className={style.textarea}
              component='textarea'
              name='description'
              required
              placeholder="Descrivi l'accaduto e segnalaci tutti i dettagli che ritieni possano essere utili all'identificazione del soggetto"
              type='text'
              rows="5"
              visible='don_t_show_eye'
              errormsg={errors.email}
            />

            </div>

          </div>
          <div className={classnames(style.wrapper, style.lastcontainer)}>
            <Input
              className={style.marginBottom}
              required
              name='email'
              label='Email dove ricevere una copia della segnalazione*'
              status={email_is_valid}
              placeholder='Inserisci la tua e-mail'
              type='text'
              visible='don_t_show_eye'
              showerror={true}
              onClick={()=>{setEmail_touched(true)}}
              handleBlur={handleBlurInput}
              errormsg={errors.email}
            />
                        <div className={style.checkboxGroup} style={{paddingBottom:20}}>
              <div className={style.checkboxContainerHorizontal}>
                <Field
                  name='read_info'
                  type='checkbox'
                  id="read_info"
                />
                <label>Prima di fornire i dati richiesti, dichiaro di aver compreso le finalità di questa forma e di aver letto l'informativa sul trattamento dei tuoi dati personali, che trovi <span className={style.link} onClick={()=>console.log('premuto 2')}>qui</span></label>
              </div>
            </div>
              <div className={classnames(style.buttonContainer)}>
                <div className={classnames(style.confirm, {[style.fixedInMobileViewport]: inputFocused})}>
                  <Button
                  type="button"
                  className={classnames(style.button)}
                  disabled={(isSubmitting || errors[Object.keys(errors)[0]]) || email_is_valid == null || name_is_valid == null || surname_is_valid == null || fiscal_code_is_valid == null}>
                  invia la Segnalazione
                  </Button>
                </div>
              </div>
          </div>
          {thanks ? <Redirect to={routes.thanks.url} /> : null}
        </Form>

      )}
      </Formik>

      <Modal
          className={style.updatePasswordModal}
          modalKey={status_modal_key}
          modalState={modalState_status}
          toggleModal={modalState_status_close}
        >
          <GeneralStatusModal
            status={'error'}
            // status='accept'
            // status='warning'
            // status='error'
            message={'Errore'}

            close={modalState_status_close}
            onClickCallback={modalState_status_close}
          />
        </Modal>

    </General>
  );
};

export default Truffe;
