import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './icon.scss';

/*
      - install the package using the command npm i --save-dev  @svgr/webpack

      - verify that the config/webpack/rules/rule-svg.js file has this configuration
        above loader and ref properties

          module.exports = (opts = {prod: false}) => (
            {
              test: /\.svg$/,
              use: [
                {
                  loader: '@svgr/webpack',
                  options: {
                    ref: false
                  }
                },
                {
                  loader: require.resolve('file-loader'),
                  options: {
                    name: 'static/media/[name].[hash:8].[ext]',
                  }
                }
              ]
            }
          );

      When adding a new svg it's important to set 100% width and height. It is also advisable to inherit the color, for example style = "fill: currentColor;"
*/

import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as Exit } from './icons/exit.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as Invalid } from './icons/invalid.svg';
import { ReactComponent as Plane } from './icons/plane.svg';
import { ReactComponent as Privacy } from './icons/privacy.svg';
import { ReactComponent as Profile } from './icons/profile.svg';
import { ReactComponent as Shopping } from './icons/shopping.svg';
import { ReactComponent as StatusAccept } from './icons/accept.svg';
import { ReactComponent as StatusError } from './icons/error.svg';
import { ReactComponent as StatusWarning } from './icons/warning.svg';
import { ReactComponent as Support } from './icons/support.svg';
import { ReactComponent as User } from './icons/user.svg';
import { ReactComponent as Valid } from './icons/valid.svg';
import { ReactComponent as Voucher } from './icons/voucher.svg';
import { ReactComponent as VoucherStorico } from './icons/voucher-storico.svg';
import { ReactComponent as Welcome } from './icons/welcome.svg';
import { ReactComponent as HidePwd } from './icons/hidepassword.svg';



const ICONS = new Map([
  ['arrow', Arrow],
  ['close', Close],
  ['exit', Exit],
  ['eye', Eye],
  ['info', Info],
  ['invalid', Invalid],
  ['plane', Plane],
  ['privacy', Privacy],
  ['profile', Profile],
  ['shopping', Shopping],
  ['statusAccept', StatusAccept],
  ['statusError', StatusError],
  ['statusWarning', StatusWarning],
  ['support', Support],
  ['user', User],
  ['valid', Valid],
  ['voucher', Voucher],
  ['voucherStorico', VoucherStorico],
  ['welcome', Welcome],
  ['HidePwd', HidePwd]
]);

const NoopComponent = () => null;

const propTypes = {
  /** Proptypes go here */
  /** One of available icons */
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  /** Default Props go here */
};

const Icon = (props) => {
  const { name, className, ...otherProps } = props;
  const TheIcon = ICONS.get(name) || NoopComponent;
  return (
    <Fragment>
      <TheIcon
        fill="currentColor"
        className={classnames(className, style.icon)}
        {...otherProps} />
    </Fragment>
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

// export default Icon;

// export { ICONS as iconsMap };

export { Icon, ICONS as iconsMap };