import React from 'react';
import classnames from 'classnames';
import style from './thanks.scss';
import { General } from '../../components/template/general';
import { Header } from '../../components/ui/molecules/header';
import { routes } from '../../core/config/routes';
import { Button } from '../../components/ui/atoms/button';

const Thanks = () => {
  const email = localStorage.getItem('email')
  return (
    <General>
      <div className={style.wrapper}>
        <div className={style.header}>
        <Header
          variant='big'
          title={'Grazie per la segnalazione'}
          subtitle={'Grazie per aver aiutato Accendi Luce & Gas Coop'}
          subtitle2={'a prevenire comportamenti o azioni di disturbo da parte di persone o società non autorizzate.'}
          subtitle3={'Ti abbiamo inviato una copia della segnalazione a:'}
          email={email}
        />
        </div>

        <div className={style.container}>
          <div className={classnames(style.buttonContainer)}>
            <div className={classnames(style.confirm, style.fixedInMobileViewport)}>
              <a className={style.notUnderline} href={routes.truffe.path + ''}>
                <div className={classnames(style.first_button, style.button)}>
                  <span className={style.first_button_text}>nuova segnalazione</span>
                </div>
              </a>
              <a className={style.notUnderline} href={'https://areaclienti.accendilucegas.it/public/'}>
              <div className={classnames(style.buttonContainer)}>
                <div className={classnames(style.confirm)}>
                  <Button
                  className={classnames(style.button)}>
                  torna all'area clienti
                  </Button>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </General>
  );
};

export default Thanks;
