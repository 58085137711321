import React, { Component, Fragment } from "react";
import classnames from "classnames";

import { General } from "../../components/template/general";
import { Accordion } from "../../components/ui/organisms/accordion";
import { Breadcrumb } from "../../components/ui/molecules/breadcrumb";

import { routes } from "../../core/config/routes";

import style from "./privacy.scss";

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
  breadcrumbTitle: "Torna indietro",
  breadcrumbUrl: routes.truffe.path
};

const Privacy = ({ className, breadcrumbUrl, breadcrumbTitle }) => (
  <General>
    <div className={classnames(style.privacy, className)}>
      <Accordion title="privacy" iconName="privacy" blocked>
        <div className={style.privacyContent}>
          <Breadcrumb
            className={style.breadcrumb}
            label={breadcrumbTitle}
            url={breadcrumbUrl}
          />
          <h2 className={style.title}>Informativa sulla privacy</h2>
          <p>
            Ai sensi dell’art. 13 del Regolamento Europeo 2016/679 (GDPR) e
            dell’art. 13 del D.Lgs 30 giugno 2003 n. 196 “Codice in materia di
            protezione dei dati personali”, e successive modifiche (Codice
            Privacy).
          </p>
          <span>Con la privacy policy </span>
          <a href="https://www.accendilucegas.it/modulistica-file/?f=ACCENDI-privacy/Nota-informativa-PRIVACY.pdf">
            presente nel link
          </a>
          <span>
            {" "}
            desideriamo illustrare chiaramente i motivi per cui raccogliamo e
            trattiamo i Suoi dati, in modo da consentirle di effettuare le sue
            scelte in materia di privacy in maniera informata
          </span>{" "}
          <br />
          <br />
          <p>
            Solo con il Suo consenso, potremo trattare i Suoi dati per inviarle
            newsletter, offrirle servizi di assistenza alla clientela,
            comunicazioni commerciali e promozionali o per svolgere ricerche di
            mercato (finalità di marketing), per analisi su abitudini,
            preferenze e scelte di consumo, per conoscere meglio i suoi gusti o
            interessi come la scelta dei punti vendita di maggiore interesse
            (finalità di profilazione). Sempre solo con il Suo consenso, potremo
            anche comunicare i Suoi dati a soggetti terzi, che, operanti nei
            settori commerciali di beni e servizi, li possano usare per finalità
            di profilazione e/o di comunicazione commerciale, perché da noi
            valutate in grado di offrirle servizi utili e vantaggi.
          </p>
          <span>
            Avrà sempre il diritto di modificare le sue scelte in materia di
            privacy, di ottenere le informazioni che desidera circa l’utilizzo
            dei Suoi dati personali e di opporsi, per motivi legittimi, al loro
            trattamento, indirizzandola al Responsabile della Protezione dei
            Dati:{" "}
          </span>
          <a href="mailto:DataProtectionOfficer@alleanzalucegas.it">
            DataProtectionOfficer@alleanzalucegas.it
          </a>
          <br/><br/>
          <p>
            Per qualsiasi chiarimento, richiesta o per esercitare i Suoi
            diritti, potrà contattarci al numero verde 800 208468.
          </p>
        </div>
      </Accordion>
    </div>
  </General>
);

Privacy.propTypes = propTypes;
Privacy.defaultProps = defaultProps;

export { Privacy };
