import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './circle.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const Circle = ({
  text,
  className
}) => (
  <div className={classnames(style.circle, className)}>
    {text}
  </div>
);

Circle.propTypes = propTypes;
Circle.defaultProps = defaultProps;

export { Circle };
