import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './modal.scss';
import { Icon } from '../ui/atoms/icon';

const propTypes = {
  modalKey: PropTypes.string,
  modalState: PropTypes.object
};
const defaultProps = {
  modalKey: 'default_key',
  modalState: {default_key: true}
};

const Modal = ({
  children,
  className,
  modalState,
  modalKey,
  toggleModal,
  blackIcon,
  error,
  deleteErrors,
  fullScreenMobile,
  ...otherProps
}) => {
  const closeModal = () => {
    toggleModal(modalKey);
    document.body.style.removeProperty('overflow');
    if(error) {
      deleteErrors();
      window.location.href = '/';
    }
  };

  return (
    <Fragment>
      {
        modalState[modalKey] &&
        <div className={style.wrapper}>
          <div className={style.overlay} onClick={closeModal}></div>
          <div className={style.modalContainer}>
            <div className={classnames(style.modal, {[style.fullScreenMobile]: fullScreenMobile}, className)} {...otherProps}>
              <Icon
              name='close'
              onClick={closeModal}
              className={style.icon} />
              <div classnames={style.children}>{children}</div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export { Modal };
