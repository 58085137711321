/* @flow */
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';
import style from './app.scss';
import { HwHistory } from './core/utils/history';
import { routes } from './core/config/routes';
import Truffe from "./views/truffe/truffe";
import Thanks from './views/thanks/thanks';
import {Policy} from './views/policy/policy';
import {Privacy} from './views/privacy/privacy';
import { ProtectedRoute } from './helpers/router/protected-route';
import { getParamFromUrl } from './helpers/utils';

class AppView extends Component {

  constructor(props){
    super(props);
    const id = getParamFromUrl('id');
    const type = getParamFromUrl('type');
    if(!!id && !!type){
      localStorage.setItem('idFromUrl', id);
      localStorage.setItem('typeFromUrl', type);
    }
  }

  render() {
    return (
      <div className={classnames(style.App)}>
        <Router history={HwHistory}>
          <div className={classnames(style.AppContainer)}>
            <div className={classnames(style.AppBody)}>
              <Switch>
                <ProtectedRoute
                  guard={true}
                  exact
                  path={routes.truffe.path}
                  Component={Truffe} />
                <Route
                  exact
                  path={routes.thanks.path}
                  component={Thanks} />
                <Route
                  exact
                  path={routes.privacy.path}
                  component={Privacy} />
                <Route
                  exact
                  path={routes.policy.path}
                  component={Policy} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default AppView;
