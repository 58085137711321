import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppView from './app';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
      <AppView />
  , document.querySelector('main'));

registerServiceWorker();