import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './button.scss';

const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
  variant: 'primary'
};

const Button = ({
  children,
  disabled,
  onClick,
  variant,
  className,
  ...otherProps
}) => (
    <button
      onClick={onClick}
      className={classnames(style.button, style[variant], {[style.disabled]: disabled}, className)}
    >
    <span {...otherProps} className={classnames(style.textButton, {[style.disabled]: disabled})}>{children}</span>
  </button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export { Button };
