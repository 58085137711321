import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './accordion.scss';

import { PlusMinus } from '../../atoms/plus-minus';
import { Icon } from '../../atoms/icon/icon';
import { Circle } from '../../atoms/circle/circle';


const propTypes = {
  /** Proptypes go here */
};
const defaultProps = {
  /** Default Props go here */
};

const Accordion = ({
  children,
  iconName,
  number,
  title,
  className,
  blocked,
  ...otherProps
}) => {
  const [isOpen, setIsOpen]                           = useState(false);
  const refContent                                    = useRef(null);
  const [maxHeightRefContent, setMaxHeightRefContent] = useState('0px');

  useEffect(()=>{
    if (title === 'i tuoi buoni digitali') {
      setTimeout(()=>{
        setIsOpen(true)
        setMaxHeightRefContent(isOpen ? '0px' : `${refContent.current.scrollHeight}px` );
      }, 300);
    }
  }, []);

  function toggleAccordion() {
    setIsOpen(!isOpen);
    setMaxHeightRefContent(isOpen ? '0px' : `${refContent.current.scrollHeight}px` );
  }

  return (
    <div className={classnames(style.accordion, {
      [style.blocked]: blocked,
      [style.open]: isOpen,
      [style.close]: !isOpen,
      [style.blocked]: blocked
      }, className)}
    {...otherProps}>
      <div
        className={classnames(style.header)}
        onClick={blocked ? () => {} : toggleAccordion}
      >
        <div className={style.left}>
          {iconName && <Icon className={style.icon} name={iconName}/>}
          <span className={style.title}>{title}</span>
          {number && number > 0 ? <Circle className={style.circleNumber} text={number} /> : ''}
        </div>
        {!blocked && 
          <div className={style.right}>
            <span className={style.statusLabel}>
              {isOpen ? 'RIDUCI' : 'ESPANDI'}
            </span>
            <PlusMinus className={style.iconPM} status={isOpen } />
          </div>
        }
      </div>

        <div
          ref={refContent}
          style={blocked ? {} : {maxHeight: maxHeightRefContent}}
          className={classnames(style.wrapContent, {[style.open]: isOpen || blocked})}
        >
          <div className={style.content}>
            {children}
          </div>
        </div>

    </div>
  );
};

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export { Accordion };
