export const routes =  {
  truffe: {
    path: '/',
    url: '/truffe'
  },
  thanks: {
    path: '/thanks',
    url: '/thanks'
  },
  policy: {
    path: '/policy',
    url: '/policy'
  },
  privacy: {
    path: '/privacy',
    url: '/privacy'
  },
};
