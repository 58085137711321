
var content = require("!!../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-2-3!./truffe.scss");

if(typeof content === 'string') content = [[module.id, content, '']];

var transform;
var insertInto;



var options = {"hmr":false}

options.transform = transform
options.insertInto = undefined;

var update = require("!../../../node_modules/style-loader/lib/addStyles.js")(content, options);

if(content.locals) module.exports = content.locals;

